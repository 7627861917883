import React, { Component } from 'react';
import { connect } from 'react-redux'

import ExFormElement from '../../../components/UI/Inputs/ExFormElement/ExFormElement';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './NewExForm.module.css';
import axios from '../../../axios';
import { checkValidity } from '../../../shared/utility';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
// import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

class NewExForm extends Component {
    state = {
        exerciseForm: {
            sets: {
                value: '',
                touched: false,
                validation: {
                    required: true,
                    aboveZero: true
                },                
                elementType: 'tel',
                placeholder: "",
                valid: false
            },
            reps: {
                value: '',
                touched: false,
                validation: {
                    required: true,
                    aboveZero: true
                },                
                elementType: 'tel',
                placeholder: "",
                valid: false
            },
            kg: {
                value: '',
                touched: false,
                validation: {
                    required: true,
                    nonNegative: true
                },                
                elementType: 'tel',
                placeholder: "",
                valid: false
            }
        },
        loading: false,
        postResponse: null,
        formIsValid: false,
        isWeight: true
    }
    
    toggleWeightOrTimeHandler = () => {
        // console.log('[toggle clicked]');
        this.setState(prevState => {
            if (prevState.isWeight === true) {
                const newExForm = {
                    sets: {...prevState.exerciseForm.sets},
                    reps: {...prevState.exerciseForm.reps},
                    'time (s)': {
                        value: '',
                        touched: false,
                        validation: {
                            required: true,
                            aboveZero: true
                        },                
                        elementType: 'tel',
                        placeholder: "",
                        valid: false
                }};
                return {isWeight: false, exerciseForm: newExForm}
            } else {
                const newExForm = {
                    sets: {...prevState.exerciseForm.sets},
                    reps: {...prevState.exerciseForm.reps},
                    kg: {
                        value: '',
                        touched: false,
                        validation: {
                            required: true,
                            nonNegative: true
                        },                
                        elementType: 'tel',
                        placeholder: "",
                        valid: false
                }};
                return {isWeight: true, exerciseForm: newExForm}
            }
        });
    }

    inputChangedHandler = (event, id) => {
        const updatedExForm = {
            ...this.state.exerciseForm
        };

        const updatedFormElement = {
            ...updatedExForm[id]
        };

        updatedFormElement.value = event.target.value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedExForm[id] = updatedFormElement;

        let formIsValid = true;
        for (let inputId in updatedExForm) {
            formIsValid = updatedExForm[inputId].valid && formIsValid;
        }
        
        this.setState({exerciseForm: updatedExForm, formIsValid: formIsValid});

    }

    logNewExerciseHandler = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        const formData = {};

        for (let id in this.state.exerciseForm) {
            formData[id] = this.state.exerciseForm[id].value;
        }

        const exercise = {
            exerciseName: this.props.exName,
            exerciseData: formData,
            exerciseDate: new Date()
        };

        axios.post('/users/' + this.props.userId + '/exerciseLog.json?auth=' + this.props.token, exercise)
        .then(response => {
            this.setState({loading: false, postResponse: response.data});
            this.props.logged();
        })
        .catch(error => {
            this.setState({loading: false});
            this.props.failed();
        });

    }
    
    render () {

        const formElementsArray = [];
        for (let key in this.state.exerciseForm) {
            formElementsArray.push({
                id: key,
                config: this.state.exerciseForm[key]
            });
        }
        let form = (
            <Aux>
                <form onSubmit={this.logNewExerciseHandler}>
                    {formElementsArray.map(formElement => (
                        <ExFormElement
                            key={formElement.id}
                            units={formElement.id}
                            elementType={formElement.config.elementType} 
                            placeholder={formElement.config.placeholder} 
                            value={formElement.config.value}
                            shouldValidate={formElement.config.validation}
                            invalid={!formElement.config.valid}
                            touched={formElement.config.touched}
                            autofocus={formElement.id==='sets' ? true : false}
                            size="lg"
                            changed={(event) => this.inputChangedHandler(event, formElement.id)}
                        />
                    ))}
                    <Button btnSize="Large" 
                    btnColor={this.props.theme==='dark' ?  "Green" : "Blue"}
                    disabled={!this.state.formIsValid}>Log Exercise</Button>
                </form>
                <Button btnSize="Large" 
                clicked={this.toggleWeightOrTimeHandler}>Toggle weight/time</Button>
             </Aux>
        );

        if (this.state.loading) {
            form = <Spinner /> 
        }

        return (

            <div className={classes.NewExForm}>
                <h5>Logging {this.props.exName.replace('_', ' ')}...</h5>
                {form}
            </div>


        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        theme: state.auth.settings.theme
    };
};

export default connect(mapStateToProps)(NewExForm);