import React from 'react';
import { connect } from 'react-redux'

import classes from './GradientKey.module.css';

const gradientKey = (props) => (
    <div className={props.theme==='light' ? classes.Key : [classes.Key,classes.KeyDark].join(' ')} onClick={props.clicked}>
        <h6><strong>&nbsp;LAST WORKOUT</strong></h6>
        <div 
        className={classes.Bar}
        style={{backgroundImage: "linear-gradient(" + props.badColour + "," +  props.goodColour + ")"}}
        ></div>
        <div className={[classes.Green, classes.Label].join(' ')}>&nbsp;&lt;&nbsp;{props.gt}{props.gt==='1' || props.gt===1 ? ' Day' : ' Days'}</div>
        <div className={[classes.Red, classes.Label].join(' ')}>&nbsp;&gt;&nbsp;{props.rt}{props.rt==='1' || props.rt===1 ? ' Day' : ' Days'}</div>
    </div>
);

const mapStateToProps = state => {
    return {
        theme: state.auth.settings.theme,
        goodColour: state.auth.settings.goodColour,
        badColour: state.auth.settings.badColour
    };
};

export default connect(mapStateToProps)(gradientKey);
