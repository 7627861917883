import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const rhomboid = (props) => {

    let click = () => {props.clicked('rhomboid')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('rhomboid') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['rhomboid'];
    }


    return (
    <Aux>

    <path 
    onClick={click}
    className={classname}
    fill={fill} 
    d="M1429 6436 c-4 -4 30 -44 75 -89 81 -82 82 -82 175 -289 108 -240
    146 -308 218 -388 46 -50 192 -160 213 -160 4 0 14 7 21 17 11 13 14 88 14
    391 l0 374 -30 24 c-33 26 -127 62 -250 95 -63 17 -118 23 -255 26 -96 3 -178
    2 -181 -1z"/>
    <path 
    onClick={click}
    className={classname}
    fill={fill} 
    d="M2600 6423 c-123 -20 -312 -83 -356 -118 l-29 -23 0 -369 c0 -287 3
    -373 13 -391 16 -29 25 -26 116 36 138 94 209 198 341 496 43 98 95 200 115
    225 40 50 110 116 130 123 8 2 10 9 6 16 -5 9 -51 12 -159 11 -84 -1 -163 -4
    -177 -6z"/>
    </Aux>
);
}

export default rhomboid;