import React from 'react';

import classes from './Exercise.module.css';

import Neck from '../../Muscles/Man/Front/Neck/Neck';
import Traps from '../../Muscles/Man/Front/Traps/Traps'; 
import Shoulders from '../../Muscles/Man/Front/Shoulders/Shoulders'; 
import Pecs from '../../Muscles/Man/Front/Pecs/Pecs'; 
import Biceps from '../../Muscles/Man/Front/Biceps/Biceps'; 
import Obliques from '../../Muscles/Man/Front/Obliques/Obliques'; 
import Abs from '../../Muscles/Man/Front/Abs/Abs'; 
import Forearms from '../../Muscles/Man/Front/Forearms/Forearms'; 
import Adductors from '../../Muscles/Man/Front/Adductors/Adductors';
import Quads from '../../Muscles/Man/Front/Quads/Quads';
import Abductors from '../../Muscles/Man/Front/Abductors/Abductors';
import Shins from '../../Muscles/Man/Front/Shins/Shins';

import Calves from '../../Muscles/Man/Back/Calves/Calves';
import ForearmsBack from '../../Muscles/Man/Back/Forearms/Forearms';
import Glutes from '../../Muscles/Man/Back/Glutes/Glutes';
import Hamstrings from '../../Muscles/Man/Back/Hamstrings/Hamstrings';
import Lats from '../../Muscles/Man/Back/Lats/Lats';
import LowerBack from '../../Muscles/Man/Back/LowerBack/LowerBack';
import Rhomboid from '../../Muscles/Man/Back/Rhomboid/Rhomboid';
import ShouldersBack from '../../Muscles/Man/Back/Shoulders/Shoulders';
import TrapsBack from '../../Muscles/Man/Back/Traps/Traps';
import Triceps from '../../Muscles/Man/Back/Triceps/Triceps';
import ObliquesBack from '../../Muscles/Man/Back/Obliques/Obliques';


const exercise = (props) => (
    <div className={classes.Exercise} onClick={props.clicked}>
        
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            //  width="307.000000pt" height="594.000000pt"
            viewBox="0 0 307 594"
            width="50px"
            style={{position: "absolute",left: "3px", top: "3px"}}
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g transform="translate(-7.000000,594.000000) scale(.1,-.1)" stroke="none">
            <Neck  icon muscles={props.muscles}/>          
            <Traps  icon muscles={props.muscles} />
            <Shoulders  icon muscles={props.muscles}/> 
            <Pecs  icon muscles={props.muscles} />
            <Biceps  icon muscles={props.muscles} />
            <Obliques icon muscles={props.muscles} />
            <Abs icon muscles={props.muscles} />
            <Forearms  icon muscles={props.muscles}/>
            <Adductors  icon muscles={props.muscles} />
            <Quads  icon muscles={props.muscles} />
            <Abductors  icon muscles={props.muscles} />
            <Shins  icon muscles={props.muscles}/>
            </g>
        </svg>

        <div className={classes.TitleArea}>
            <h5>{props.name}</h5>
        </div>
        
        {/* <p>{props.muscles.join(', ').toUpperCase()}</p> */}




        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            //  width="307.000000pt" height="594.000000pt"
            viewBox="0 0 340.000000 700.000000"
            width="48px"
            style={{position: "absolute",right: "3px", top: "3px"}}
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g transform="translate(-50,723.000000) scale(0.100000,-0.100000)" stroke="none">
            <Calves icon muscles={props.muscles} />
            <ForearmsBack icon muscles={props.muscles}  />
            <Glutes icon muscles={props.muscles} />
            <Hamstrings icon muscles={props.muscles} />
            <Lats icon muscles={props.muscles} />
            <LowerBack icon muscles={props.muscles} />
            <Rhomboid icon muscles={props.muscles} />
            <ShouldersBack icon muscles={props.muscles} />
            <TrapsBack icon muscles={props.muscles} />
            <Triceps icon muscles={props.muscles} />
            <ObliquesBack icon muscles={props.muscles}/>
            </g>
        </svg>
    </div>
);

export default exercise;