import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Alert from '../../components/UI/Alert/Alert';
import FrontMan from '../../components/Front/Man/FrontMan';
import FrontWoman from '../../components/Front/Woman/FrontWoman';
import BackMan from '../../components/Back/Man/BackMan';
import BackWoman from '../../components/Back/Woman/BackWoman';
import Exercises from '../../components/Exercises/Exercises';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/UI/Button/Button';
import classes from './FullBody.module.css';
import Modal from '../../components/UI/Modal/Modal';
import NewExForm from './NewExForm/NewExForm';
import Spinner from '../../components/UI/Spinner/Spinner';
import * as actions from '../../store/actions/index';
import MuscleHistory from '../../components/History/MuscleHistory/MuscleHistory';
import CustomExForm from './CustomExForm/CustomExForm';
import axios from '../../axios';
import InitialSettings from '../InitialSettings/InitialSettings';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

class FullBody extends Component {
    state = {
        lastWorkout: {
            shoulders: 'R',
            abs: 'G'
        },
        // choosingNewOrHistory: false,
        viewingHistory: false,
        muscleLastClicked: '',
        choosingNewExercise: false,
        loggingNewExercise: false,
        displayExLoggedAlert: false,
        alertMessage: null,
        displayCustomExAddedAlert: false,
        exerciseLastClicked: '',
        loading: false,
        addingCustomExercise: false,
        response: null
    }

    muscleClickedHandler = (muscle) => {
        // this.setState({choosingNewOrHistory: true, muscleLastClicked: muscle});
        this.setState({viewingHistory: true, muscleLastClicked: muscle});
    }

    loadExercisesHandler = () => {
        this.setState({viewingHistory: false, choosingNewExercise: true})
    }

    exerciseCancelHandler = () => {
        this.setState({
            choosingNewExercise: false, 
            // choosingNewOrHistory: false, 
            loggingNewExercise: false, 
            viewingHistory: false,
            addingCustomExercise: false
        });
    }

    exerciseSelectedHandler = (exName) => {
        if (this.props.isAuthenticated) {
            this.setState({
                choosingNewExercise: false, 
                loggingNewExercise: true,
                exerciseLastClicked: exName
            });
        } else {
            this.props.history.push('/auth');
        }
        
    }

    exerciseLoggedHandler = () => {
        this.setState({loggingNewExercise: false, displayAlert: true, alertMessage: "Exercise Logged"});
        this.props.onFetchExerciseLog(this.props.token, this.props.userId, this.props.greenTime, this.props.redTime, this.props.goodColour, this.props.badColour);
        setTimeout(()=>{
            this.setState({displayAlert: false});
        },1000);
    }

    exerciseFailedToLogHandler = () => {
        this.setState({loggingNewExercise: false});
    }

    // showHistorySelectedHandler = () => {
    //     this.setState({choosingNewOrHistory: false, viewingHistory: true});
    // }

    ragKeyClickedHandler = () => {
        this.props.history.push('/settings');
    }

    addCustomExerciseHandler = () => {
        this.setState({choosingNewExercise: false, addingCustomExercise: true});
    }

    customExerciseAddedHandler = () => {
        this.setState({addingCustomExercise: false, displayAlert: true, alertMessage: "Exercise Added"});
        this.props.onFetchExerciseLog(this.props.token, this.props.userId, this.props.greenTime, this.props.redTime, this.props.goodColour, this.props.badColour);
        setTimeout(()=>{
            this.setState({displayAlert: false});
        },1000);
    }

    customExerciseFailedHandler = () => {
        this.setState({addingCustomExercise: false});
    }

    deleteExerciseHistoryHandler = (exerciseId) => {
        this.setState({loading: true});
        axios.delete('/users/' + this.props.userId + '/exerciseLog/' + exerciseId + '.json?auth=' + this.props.token)
        .then(response => {
            this.setState({loading: false, displayAlert: true, alertMessage:"Exercise Deleted", viewingHistory: false, response: response.data});
            this.props.onFetchExerciseLog(this.props.token, this.props.userId, this.props.greenTime, this.props.redTime, this.props.goodColour, this.props.badColour);
            setTimeout(()=>{
                this.setState({displayAlert: false});
            },1000);
        }).catch(err => {
            this.setState({loading: false, viewingHistory: false});
        });
    }
    

    render () {

        let fullBody = <div style={{paddingTop: "50px"}}><Spinner /></div>;
        
        if (!this.props.loading && !this.state.loading && this.props.muscleRAGs && this.props.gender) {
            switch (this.props.gender) {
                case ('female'):
                    fullBody = (
                        <Aux>
                            <FrontWoman clicked={this.muscleClickedHandler} 
                            colours={this.props.colourType==='gradient' ? this.props.muscleGradients : this.props.muscleRAGs}
                            gt={this.props.greenTime} rt={this.props.redTime} keyClicked={this.ragKeyClickedHandler}/>
                            <br />
                            <BackWoman clicked={this.muscleClickedHandler} 
                            colours={this.props.colourType==='gradient' ? this.props.muscleGradients : this.props.muscleRAGs}
                            gt={this.props.greenTime} rt={this.props.redTime} keyClicked={this.ragKeyClickedHandler}/>
                            <br />
                        </Aux>
                    );
                    break;
                default:
                    fullBody = (
                        <Aux>
                            <FrontMan clicked={this.muscleClickedHandler} 
                            colours={this.props.colourType==='gradient' ? this.props.muscleGradients : this.props.muscleRAGs}
                            gt={this.props.greenTime} rt={this.props.redTime} keyClicked={this.ragKeyClickedHandler}/>
                            <br />
                            <BackMan clicked={this.muscleClickedHandler} 
                            colours={this.props.colourType==='gradient' ? this.props.muscleGradients : this.props.muscleRAGs}
                            gt={this.props.greenTime} rt={this.props.redTime} keyClicked={this.ragKeyClickedHandler}/>
                            <br />
                        </Aux>
                    );    
            }
            
        }

        let exercises = null;
        if (this.state.choosingNewExercise) {
            exercises = (
                <Exercises 
                    exerciseList={this.props.exerciseList} 
                    muscleClicked={this.state.muscleLastClicked} 
                    clicked={this.exerciseSelectedHandler}
                    addCustomEx={this.addCustomExerciseHandler}
                    rag={this.props.muscleRAGs}
                />
            );
        }

        let newExerciseForm = null;
        if (this.state.loggingNewExercise) {
            newExerciseForm = (
                <NewExForm exName={this.state.exerciseLastClicked} logged={this.exerciseLoggedHandler} failed={this.exerciseFailedToLogHandler}/>
            );
        }

        let addCustomExercise = null;
        if (this.state.addingCustomExercise) {
            addCustomExercise = (
                <CustomExForm added={this.customExerciseAddedHandler} failed={this.customExerciseFailedHandler}/>
            );
        }

        let history = null;
        if (this.state.viewingHistory && !this.state.loading) {
            history = (
                <Aux>
                    <h3 className={this.props.theme === 'light' ? classes.Title : classes.TitleDark}>
                        {this.state.muscleLastClicked.toUpperCase()} 
                        <p className={classes.Footnote} style={{paddingTop: "5px"}}>Click on a workout to remove</p>
                    </h3>
                    <Button 
                        clicked={() => {this.loadExercisesHandler(this.state.muscleLastClicked)}} 
                        btnColor={this.props.theme==='dark' ?  "White" : "Blue"} 
                        style={{width: "100%", textTransform: "none"}}
                        btnSize="Large"
                        >Log New Exercise
                    </Button>
                    <MuscleHistory delete={this.deleteExerciseHistoryHandler} log={this.props.exerciseLog} muscle={this.state.muscleLastClicked} list={this.props.exerciseList}/>
                </Aux>
            );
        }

        let genderChoice = null;
        if (!this.props.gender && !this.props.loading) {
            genderChoice = <InitialSettings />
        }

        let modalSpinner = null;
        if (this.state.loading || this.props.loading) {
            modalSpinner = <Spinner />
        }

        let headerText = null;
        if (!this.state.loading && !this.props.loading) {
            headerText = (
                <Aux>
                    <h4 className={this.props.theme === 'light' ? classes.Title : classes.TitleDark}
                    >Welcome to RagFit!</h4>
                    <h6 className={this.props.theme === 'light' ? classes.Title : classes.TitleDark}
                    >Click on a muscle to record a workout</h6>   
                </Aux>
            );
        }

        let footerText = null;
        if (!this.state.loading && !this.props.loading) {
            footerText = (
                <h6 className={this.props.theme === 'light' ? classes.Title : classes.TitleDark}
                >
                    <p>Have a question? Let us know <NavLink to="/contact">here!</NavLink></p>
                    <p className={classes.Footnote}>Note: Muscles not commonly exercised (neck, forearms and shins) will always show as up-to-date</p>  
                </h6>  
            );
        }
        
        return (
            <div className={classes.FullBody}>
                <Alert show={this.state.displayAlert}>{this.state.alertMessage}</Alert>
                {headerText}
                {fullBody}
                {footerText}
                <Modal 
                    show={this.state.choosingNewExercise || this.state.loggingNewExercise 
                        || this.state.viewingHistory || (!this.props.gender && !this.props.loading) || this.state.addingCustomExercise } 
                    modalClosed={this.exerciseCancelHandler}
                >
                    {modalSpinner}
                    {genderChoice}
                    {exercises}
                    {newExerciseForm}
                    {history}
                    {addCustomExercise}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
        userId: state.auth.userId,
        loading: state.auth.loading,
        exerciseLog: state.auth.exerciseLog,
        muscleRAGs: state.auth.muscleRAGs,
        muscleGradients: state.auth.muscleGradients,
        greenTime: state.auth.settings.greenTime,
        redTime: state.auth.settings.redTime,
        gender: state.auth.settings.gender,
        theme: state.auth.settings.theme,
        colourType: state.auth.settings.colourType,
        goodColour: state.auth.settings.goodColour,
        badColour: state.auth.settings.badColour,
        exerciseList: state.auth.exerciseList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetSettings: (token, userId) => dispatch(actions.getSettings(token, userId)),
        onUpdateSettings: (token, userId, settings) => dispatch(actions.updateSettings(token, userId, settings)),
        onFetchExerciseLog: (token, userId, greenTime, redTime, goodColour, badColour) => dispatch(actions.fetchExerciseLog(token, userId, greenTime, redTime, goodColour, badColour))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(FullBody, axios));