import React from 'react';
import { connect } from 'react-redux';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase/app";
import * as actions from '../../store/actions/index';

import classes from './AuthUI.module.css'
import { auth } from '../../firebase';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import Aux from '../../hoc/Auxiliary/Auxiliary';


class AuthUI extends React.Component {

  state = {
    isNative: false,
    loading: false,
    error: null
  }
  
  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(
        user => {
            if (user) {
                auth.currentUser.getIdToken()
                .then(idToken => {
                    this.props.onSignIn(idToken, user.uid);
                })
                .catch(err => {
                    console.log(err);
                }); 
            } else {
                // console.log('[compDidMount] auth observer state change, no current user found');
            }
        }
    );
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  nativeGoogleSignInHandler = () => {
    window.ReactNativeWebView.postMessage('googleSignInClicked');
  }

  googleSignInHandler = () => {
    this.setState({loading: true})
    console.log('[googleSignInHandler');
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }

  render() {

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("isNative");
    }

    document.addEventListener("message", message => {
      if (message.data === 'isNative' && !this.state.isNative) {
        this.setState({isNative: true});
      }
    });

    window.addEventListener("message", message => {
      if (message.data === 'isNative' && !this.state.isNative) {
        this.setState({isNative: true});
      }
    });

    const uiConfigWeb = {
      signInFlow: 'redirect',
      signInOptions: [
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
    };
    
    let googleButton = ( 
      <div onClick={this.googleSignInHandler} style={{width: "80%", cursor: "pointer", padding: "5px", position: "relative", minHeight: "40px",
      borderRadius: "2px",
      border: "thin solid lightgrey",
      color: "#757575",
      
      boxShadow: "1px 1px 1px lightgrey",
      whiteSpace: "nowrap", margin: "5px auto", maxWidth: "220px", 
      textTransform:"none", fontWeight: "500"}}>
        <div style={{position: "absolute", left: "15px", top: "5px"}}>
            <img width="17px" style={{marginTop:"2px", marginRight:"8px"}} alt="Google sign-in" 
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
        </div>
        <span style={{fontFamily: "'Roboto', sans-serif",fontSize: "14px", display: "inline-block",
      verticalAlign: "middle",
      paddingLeft: "42px", paddingRight: "42px"}}>
        Sign in with Google
        </span>
        
      </div>
    );
    if (this.state.isNative) {
      googleButton = null;
    }

    let main = <Spinner />;
    if (!this.state.loading) {
      main = (
        <Aux>
          <p>Sign in to begin tracking and visualising your workouts:</p>
          {googleButton}
          {/* {!this.state.isNative ? <StyledFirebaseAuth uiConfig={uiConfigWeb} firebaseAuth={auth}/> : null} */}
          <StyledFirebaseAuth uiConfig={uiConfigWeb} firebaseAuth={auth}/>
          <br />
          <p>Or, proceed as a guest to try it out:</p>
          <Button btnColor="Green" clicked={() => auth.signInAnonymously().catch(err => {console.log(err)})}>Try as Guest</Button>
        </Aux>

      );
    }
      return (
        <div className={this.props.theme==='dark' ? [classes.AuthUI, classes.AuthUIDark].join(' ') : classes.AuthUI}>
          <h3>Welcome to RagFit!</h3>
          <br />
          {main}
        </div>
      );
    }
};

const mapStateToProps = state => {
    return {
        isSignedIn: state.auth.isSignedIn,
        theme: state.auth.settings.theme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignIn: (token, userId) => dispatch(actions.signIn(token, userId))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AuthUI);