import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const traps = (props) => {
    let click = () => {props.clicked('traps')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('traps') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['traps'];
    }

    return (
    <Aux>
        <path
        onClick={click}
        className={classname}
        fill={fill}
        d="M1263 5561 c-67 -36 -125 -69 -129 -73 -4 -5 70 -8 164 -8 l172 0
        -35 69 c-19 38 -38 71 -42 74 -5 2 -63 -25 -130 -62z"/>
        <path
        onClick={click}
        className={classname}
        fill={fill}
        d="M1756 5621 c-3 -5 -22 -38 -41 -75 l-36 -66 170 0 c94 0 171 2 171 5
        0 2 -29 20 -65 40 -36 19 -94 51 -129 70 -36 19 -67 31 -70 26z"/>
    </Aux>
    );
};

export default traps;