import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const traps = (props) => {

    let click = () => {props.clicked('traps')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('traps') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['traps'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1301 6222 c-5 -10 -15 -58 -21 -106 -17 -139 -19 -141 -140 -180 -52 -17 -66 -25 -68 -44 -5 -35 17 -91 43 -108 27 -18 227 -31 288 -20 55 10 56 34 6 138 l-42 90 -1 122 -1 121 -27 3 c-18 2 -30 -3 -37 -16z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1646 6212 c-4 -10 -5 -65 -4 -123 2 -103 1 -105 -40 -192 -23 -49 -42 -95 -42 -103 0 -7 11 -21 25 -30 22 -14 45 -16 155 -12 147 6 171 16 190 72 18 56 10 77 -35 93 -144 48 -148 52 -165 188 -6 49 -15 96 -21 107 -13 24 -56 24 -63 0z"/>

    </Aux>
);
}

export default traps;