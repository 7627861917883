import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const obliques = (props) => {

    let click = () => {props.clicked('obliques')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('obliques') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['obliques'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M1514 4825 c20 -114 37 -265 46 -420 12 -191 14 -209 30 -203 16 5 140 199 
            140 219 0 20 -180 383 -206 415 -14 18 -15 17 -10 -11z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M2791 4747 c-81 -152 -161 -319 -161 -338 0 -15 102 -180 132 -213 19 -22 26 18 38 197 6 104 
            20 246 30 315 10 70 17 128 15 130 -1 2 -26 -39 -54 -91z"/>
            
    </Aux>
);
}

export default obliques;