import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';




const obliques = (props) => {
    let click = () => {props.clicked('obliques')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('obliques') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['obliques'];
    }
    
    return (
<Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M934 4744 c11 -98 49 -160 135 -222 l75 -53 24 48 c35 69 85 128 127
        148 20 9 35 19 32 21 -2 2 -53 13 -113 24 -133 24 -203 44 -251 72 l-36 22 7
        -60z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2200 4784 c-44 -24 -172 -62 -255 -74 -141 -21 -136 -19 -94 -40 46
        -24 111 -96 134 -148 21 -47 37 -51 75 -22 14 11 29 20 34 20 18 0 76 52 92
        81 13 26 48 188 41 195 -1 1 -13 -4 -27 -12z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M970 4455 c0 -25 6 -60 14 -78 15 -36 73 -90 132 -124 l39 -21 -1 80
        0 80 -45 27 c-24 15 -65 39 -91 54 l-48 28 0 -46z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2107 4455 c-85 -52 -107 -83 -107 -152 0 -64 13 -68 81 -24 74 48
        92 74 97 140 2 33 3 63 1 68 -2 5 -35 -10 -72 -32z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M998 4193 c-6 -72 7 -107 60 -154 55 -48 89 -66 101 -53 4 5 5 42 1
        82 -5 56 -12 78 -30 97 -25 26 -102 75 -118 75 -6 0 -12 -21 -14 -47z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2076 4201 c-32 -21 -62 -44 -67 -52 -11 -17 -12 -169 -1 -169 11 0
        83 49 114 77 19 18 29 42 38 89 13 67 9 94 -12 93 -7 0 -39 -17 -72 -38z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1009 3938 c0 -18 0 -113 1 -211 1 -222 10 -255 83 -324 77 -73 122
        -80 111 -19 -2 12 -12 123 -23 246 l-20 225 -63 58 c-34 31 -68 57 -75 57 -8
        0 -13 -13 -14 -32z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2066 3918 c-32 -28 -62 -62 -67 -76 -12 -31 -44 -490 -35 -499 13
        -13 79 32 132 91 l54 59 0 239 c0 189 -3 238 -13 238 -7 0 -39 -23 -71 -52z"/>
    </Aux>
    );
    
};

export default obliques;