import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const shoulders = (props) => {
    
    let click = () => {props.clicked('shoulders')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('shoulders') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['shoulders'];
    }

    return (
        <Aux>
            <path 
            onClick={click}
            className={classname}
            fill={fill}
            d="M664 4511 c-115 -42 -172 -87 -216 -175 -30 -59 -79 -233 -93 -331 l-8 -50 54 50 c30 28 82 71 116 97 76 58 93 90 104 190 12 102 34 166 72 206 17 17 29 32 26 31 -2 0 -27 -9 -55 -18z"/>
            <path 
            onClick={click}
            className={classname}
            fill={fill}
            d="M1717 4497 c38 -39 60 -103 72 -205 4 -43 16 -92 24 -109 9 -17 58 -64 109 -104 51 -40 103 -84 116 -98 l23 -26 -6 50 c-12 92 -62 270 -93 331 -45 89 -127 153 -236 183 l-39 10 30 -32z"/>
        </Aux>
    );
    
};

export default shoulders;