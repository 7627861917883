import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const traps = (props) => {
    let click = () => {props.clicked('traps')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('traps') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['traps'];
    }

    return (
    <Aux>
        <path
        onClick={click}
        className={classname}
        fill={fill}
        d="M995 4797 c0 -100 -3 -117 -24 -154 -25 -44 -43 -54 -151 -87 l-45 -14 48 -1 c27 -1 91 -6 144 -12 72 -9 97 -9 106 0 7 7 12 42 11 89 -1 63 -8 95 -39 177 -20 55 -40 103 -44 107 -3 4 -6 -43 -6 -105z"/>
        <path
        onClick={click}
        className={classname}
        fill={fill}
        d="M1401 4893 c-5 -10 -23 -57 -40 -106 -35 -100 -48 -198 -32 -241 l11 -28 68 7 c37 4 103 11 147 14 71 6 76 8 45 15 -95 23 -136 45 -160 89 -22 37 -25 54 -25 154 0 62 -1 113 -2 113 -1 0 -6 -8 -12 -17z"/>
    </Aux>
    );
};

export default traps;