import React from 'react';

import Exercise from './Exercise/Exercise';
import classes from './Exercises.module.css';
import { Button } from 'reactstrap';

const exercises = (props) => {

    const ordered = {};

    Object.keys(props.exerciseList).sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    }).forEach(key => {
        ordered[key] = props.exerciseList[key];
    });

    const exerciseList = Object.keys(ordered).map((exName) => {
        
        if (ordered[exName].indexOf(props.muscleClicked) >= 0) {
            return (
                <Exercise 
                    key={exName} 
                    name={exName} 
                    muscles={ordered[exName]}
                    clicked={() => {props.clicked(exName)}}
                    rag={props.rag}
                />
            );
        }
        else {
            return null;
        }
    });


    return (
        <div className={classes.Exercises}>
            <h3 className={classes.Title}>New Workout</h3>
            <h6 className={classes.Subtitle}>Select an exercise to record</h6>
            {exerciseList}
            <h6 className={classes.Label}>Something missing? Add your own exercise!</h6>
            <p className={classes.Sublabel}>Note: If you want to update the muscles trained by an existing exercise, add a new exercise with <b><i> exactly&nbsp;</i></b> the same name, and the muscles you select will override the original entry</p>
            <Button color="success" size="lg" onClick={props.addCustomEx}>Add Custom Exercise</Button>
        </div>
    );
    
};

export default exercises;