import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';


const shins = (props) => {
    let click = null;
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('shins') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['shins'];
    }


    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1310 1495 c0 -5 -3 -116 -7 -245 -11 -355 -9 -593 5 -710 8 -58 19 -186 26 -285 7 -99 15 -169 18 -155 8 31 24 275 44 670 17 338 10 496 -26 611 -18 58 -60 138 -60 114z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1566 1483 c-17 -55 -38 -218 -46 -363 -12 -202 -30 -759 -29 -895 l1 -100 18 105 c80 469 100 646 100 875 -1 158 -19 377 -33 392 -3 2 -8 -4 -11 -14z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M806 1468 c-41 -193 -33 -557 18 -883 53 -340 69 -432 77 -445 6 -10 8 -4 5 20 -3 19 -8 157 -11 305 -11 526 -33 868 -65 986 -12 45 -18 48 -24 17z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1054 1464 c-54 -82 -79 -300 -64 -567 6 -100 13 -238 16 -307 5 -128 34 -513 39 -518 1 -2 6 46 9 105 4 59 14 185 24 278 17 173 20 374 11 812 -2 123 -7 223 -11 223 -4 0 -15 -11 -24 -26z"/>
    </Aux>
);
}

export default shins;