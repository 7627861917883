import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logov3.png';
import classes from '../Logo/Logo.module.css';

const logo = (props) => (
    <NavLink to="/">
        <div className={classes.Logo}>
                <img src={Logo} alt="FitnessLogo"/>
                <h6>&nbsp;RagFit</h6>  
        </div>
            
    </NavLink>
);

export default logo;