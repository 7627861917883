import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';


const adductors = (props) => {
    let click = () => {props.clicked('adductors')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('adductors') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['adductors'];
    }

    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1927 3159 c-87 -87 -111 -118 -145 -189 -56 -114 -82 -220 -88 -360
        -6 -116 4 -225 20 -225 4 0 27 63 51 140 57 186 121 361 206 562 39 91 68 168
        65 170 -3 3 -52 -41 -109 -98z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1120 3235 c0 -3 13 -31 29 -63 64 -127 160 -383 240 -637 23 -71 45
        -137 50 -144 18 -29 24 39 17 197 -12 290 -52 396 -206 555 -52 53 -103 97
        -112 97 -10 0 -18 -2 -18 -5z"/>
    </Aux>
    );
};

export default adductors;