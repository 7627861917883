import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';


const adductors = (props) => {
    let click = () => {props.clicked('adductors')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('adductors') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['adductors'];
    }

    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1020 2439 c0 -11 13 -71 30 -134 27 -108 60 -267 76 -380 l7 -50 8 40 c12 65 22 378 14 436 -7 54 -7 54 -63 82 -68 33 -72 33 -72 6z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1325 2440 c-16 -11 -42 -22 -57 -26 l-28 -6 1 -211 c1 -216 10 -336 21 -287 3 14 14 70 23 125 10 55 33 159 51 230 49 198 48 214 -11 175z"/>
    </Aux>
    );
};

export default adductors;