import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const quads = (props) => {
    let click = () => {props.clicked('quads')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('quads') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['quads'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M725 2691 c-10 -32 -35 -85 -57 -117 -45 -67 -44 -49 -10 -274 11 -74 32 -222 47 -327 29 -213 47 -287 78 -320 28 -32 51 -36 86 -19 46 24 82 20 121 -14 40 -35 76 -47 99 -33 25 16 33 71 29 198 -4 103 -12 148 -56 320 -56 222 -106 373 -158 482 -26 56 -47 83 -90 118 -31 25 -60 45 -64 45 -5 0 -16 -27 -25 -59z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1573 2692 c-40 -36 -62 -67 -92 -132 -50 -110 -109 -293 -162 -500 -36 -141 -42 -184 -46 -295 -4 -147 4 -178 51 -183 20 -3 41 7 73 32 52 40 70 43 119 20 29 -14 39 -14 65 -4 56 24 72 85 124 465 20 149 43 294 52 323 18 63 13 91 -32 157 -18 28 -41 76 -50 108 -20 71 -33 72 -102 9z"/>
    </Aux>
);
}

export default quads;