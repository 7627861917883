import { muscleList, nonClickableMuslceList } from './staticLists';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isAlpha) {
        const pattern = /[^a-zA-Z ]/;
        isValid = !pattern.test(value) && isValid
    }

    if (rules.aboveZero) {
        isValid = value > 0;
    }

    if (rules.nonNegative) {
        isValid = value >= 0;
    }

    return isValid;
}


export const combineExerciseLists = (exerciseList, customList) => {
    const newCustomList = {}
    Object.values(customList).forEach(item => {
        newCustomList[Object.keys(item)] = Object.values(item)[0];
    })
    const joined = {...exerciseList, ...newCustomList};
    // const ordered = {};

    // Object.keys(joined).sort((a, b) => {
    //     console.log('[sort]',a,b);
    //     return a.toLowerCase().localeCompare(b.toLowerCase());
    // }).forEach(key => {
    //     console.log('[forEach]',key);
    //     ordered[key] = joined[key];
    // });
    return joined;
};

export const calculateRAGs = (exerciseLog, exerciseList, greenTime, redTime) => {
    const log = exerciseLog ? exerciseLog : {};
    const keys = Object.keys(log);
    const exerciseDates = [];
    
    for (let key in keys) {
        const nameAndDate = {};
        nameAndDate.name = log[keys[key]].exerciseName;
        nameAndDate.date = log[keys[key]].exerciseDate;
        exerciseDates.push(nameAndDate);
    }

    const mostRecentWorkouts = {};

    exerciseDates.forEach(workout => {
        if (workout.name in mostRecentWorkouts) {
            if (workout.date > mostRecentWorkouts[workout.name]) {
                mostRecentWorkouts[workout.name] = workout.date
            }
        } else {
            mostRecentWorkouts[workout.name] = workout.date;
        }
    });

    const lastMuscleWorkouts = {};

    // for each muscle, find the most recent workout date
    muscleList.forEach(muscle => {
        // for each workout,
        for (let workout in mostRecentWorkouts) {

            // check if workout exists in current exercise list or if it's been deprecated
            if (workout in exerciseList) {
                // check if it involves the muscle in question
                if (exerciseList[workout].indexOf(muscle) >= 0) {
                    // if we already have an entry for this muscle, check if this workout was more recent than the entry we already have
                    if (muscle in lastMuscleWorkouts) {
                        if (mostRecentWorkouts[workout] > lastMuscleWorkouts[muscle]) {
                            lastMuscleWorkouts[muscle] = mostRecentWorkouts[workout];
                        }
                    } 
                    // otherwise, add this workout date for the muscle in question
                    else {
                        lastMuscleWorkouts[muscle] = mostRecentWorkouts[workout]

                    }
                }
            }
        }
    });

    // calculate RAG status
    const muscleRAGs = {}

    muscleList.forEach(muscle => {
        let dayDiff = null;
        const red = +redTime;
        const green = +greenTime;
        const baseString = "url(#pattern"

        if (muscle in lastMuscleWorkouts) {
            dayDiff = (new Date() - new Date(lastMuscleWorkouts[muscle]))/1000/3600/24;
            if (dayDiff > red) {
                muscleRAGs[muscle] = baseString + 'Red)';
            } else if (dayDiff < green) {
                muscleRAGs[muscle] = baseString + 'DarkGreen)';
            } else if (dayDiff < green + (1/4)*(red-green)) {
                muscleRAGs[muscle] = baseString + 'Green)';
            } else if (dayDiff < green + (2/4)*(red-green)) {
                muscleRAGs[muscle] = baseString + 'Amber)';
            } else {
                muscleRAGs[muscle] = baseString + 'DarkAmber)';
            }
        }
        else {
            muscleRAGs[muscle] = baseString + 'Red)';
        }
    });

    nonClickableMuslceList.forEach(muscle => {
        muscleRAGs[muscle] =  "url(#patternNonClickable)";
    });

    return muscleRAGs;
}




export const calculateGradients = (exerciseLog, exerciseList, greenTime, redTime, goodColour, badColour) => {
    const log = exerciseLog ? exerciseLog : {};
    const keys = Object.keys(log);
    const exerciseDates = [];
    
    for (let key in keys) {
        const nameAndDate = {};
        nameAndDate.name = log[keys[key]].exerciseName;
        nameAndDate.date = log[keys[key]].exerciseDate;
        exerciseDates.push(nameAndDate);
    }

    const mostRecentWorkouts = {};

    exerciseDates.forEach(workout => {
        if (workout.name in mostRecentWorkouts) {
            if (workout.date > mostRecentWorkouts[workout.name]) {
                mostRecentWorkouts[workout.name] = workout.date
            }
        } else {
            mostRecentWorkouts[workout.name] = workout.date;
        }
    });

    const lastMuscleWorkouts = {};

    // for each muscle, find the most recent workout date
    muscleList.forEach(muscle => {
        // for each workout,
        for (let workout in mostRecentWorkouts) {

            // check if workout exists in current exercise list or if it's been deprecated
            if (workout in exerciseList) {
                // check if it involves the muscle in question
                if (exerciseList[workout].indexOf(muscle) >= 0) {
                    // if we already have an entry for this muscle, check if this workout was more recent than the entry we already have
                    if (muscle in lastMuscleWorkouts) {
                        if (mostRecentWorkouts[workout] > lastMuscleWorkouts[muscle]) {
                            lastMuscleWorkouts[muscle] = mostRecentWorkouts[workout];
                        }
                    } 
                    // otherwise, add this workout date for the muscle in question
                    else {
                        lastMuscleWorkouts[muscle] = mostRecentWorkouts[workout]

                    }
                }
            }
        }
    });

    // calculate RAG status
    const muscleGradients = {}
    
    const matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
    const match1 = matchColors.exec(goodColour);
    const match2 = matchColors.exec(badColour);
    
    const r1 =  +match1[1]; 
    const g1 = +match1[2];
    const b1 = +match1[3];
    const r2 =  +match2[1]; 
    const g2 = +match2[2];
    const b2 = +match2[3];

    muscleList.forEach(muscle => {
        let dayDiff = null;
        const red = +redTime;
        const green = +greenTime;
        let r = null;
        let g = null;
        let b = null;
        
        if (muscle in lastMuscleWorkouts) {
            dayDiff = (new Date() - new Date(lastMuscleWorkouts[muscle]))/1000/3600/24;
            const gradient = (+dayDiff - green) / (red - green);

            r = r1 + (gradient * (r2 - r1));
            g = g1 + (gradient * (g2 - g1));
            b = b1 + (gradient * (b2 - b1));
            
            if (dayDiff < green) {
                // muscleGradients[muscle] = goodColour + '100)';  
                muscleGradients[muscle] = goodColour;  
            } else if (dayDiff > red) {
                // muscleGradients[muscle] = goodColour + '1)';
                muscleGradients[muscle] = badColour;
            } else {
                muscleGradients[muscle] = 'rgb(' + r + ',' + g + ',' + b + ')';
            }
           
        } else {
            muscleGradients[muscle] = badColour;
        }
        
    });

    nonClickableMuslceList.forEach(muscle => {
        muscleGradients[muscle] = goodColour;
    });
    
    return muscleGradients;
}