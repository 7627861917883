import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';
const lats = (props) => {

    let click = () => {props.clicked('lats')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('lats') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['lats'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M1314 5815 c-15 -37 61 -390 119 -560 51 -148 131 -335 192 -452 68
            -130 165 -286 185 -298 7 -4 24 1 38 12 45 33 203 214 247 283 l43 65 7 197
            c4 108 4 224 0 257 l-7 60 -91 58 c-51 31 -158 101 -238 154 -180 121 -274
            172 -385 210 -99 34 -103 34 -110 14z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M2935 5791 c-120 -42 -187 -79 -419 -231 -309 -203 -290 -187 -300
            -247 -5 -28 -5 -144 -1 -257 l7 -207 58 -80 c64 -90 199 -241 237 -267 25 -15
            26 -15 48 10 80 93 228 381 320 623 86 224 181 619 162 669 -8 21 -19 20 -112
            -13z"/>
    </Aux>
);
}

export default lats;