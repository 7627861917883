import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const abs = (props) => {
    let click = () => {props.clicked('abs')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('abs') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['abs'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1116 3660 c-56 -17 -92 -67 -97 -135 -4 -40 -4 -40 39 -49 25 -5 59 -5 85 0 l42 9 0 90 c0 99 -4 105 -69 85z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1225 3658 c-3 -8 -5 -50 -3 -94 l3 -79 48 -9 c29 -6 62 -6 85 0 36 9 37 10 34 49 -7 75 -54 128 -124 141 -28 5 -39 3 -43 -8z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1028 3440 c-19 -11 -35 -122 -24 -165 7 -25 8 -26 94 -23 l87 3 9 72 c7 61 6 76 -8 98 -15 22 -23 25 -79 25 -34 0 -70 -5 -79 -10z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1224 3425 c-13 -21 -15 -39 -10 -92 4 -36 9 -70 12 -75 3 -4 43 -8 89 -8 82 0 85 1 91 25 3 14 3 55 -1 90 -8 77 -17 85 -104 85 -54 0 -62 -3 -77 -25z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M999 3196 c-8 -17 -9 -46 -3 -92 8 -61 12 -69 34 -75 36 -10 133 -10 149 0 17 11 26 127 12 164 -10 27 -12 27 -96 27 -81 0 -86 -1 -96 -24z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1222 3203 c-4 -10 -7 -51 -7 -91 0 -86 6 -92 85 -91 99 2 104 5 113 84 6 45 5 77 -2 92 -10 21 -16 23 -96 23 -72 0 -87 -3 -93 -17z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1033 2993 c-14 -3 -25 -14 -29 -29 -16 -64 33 -286 81 -368 32 -54 70 -90 89 -83 20 8 30 145 24 316 -5 124 -7 136 -28 153 -21 17 -80 22 -137 11z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1242 2982 c-14 -14 -22 -40 -27 -88 -9 -76 -1 -340 10 -366 25 -62 111 56 154 212 24 85 37 219 23 239 -6 8 -36 15 -74 18 -57 5 -68 3 -86 -15z"/>
    </Aux>
    );
};

export default abs;