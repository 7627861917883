import React from 'react';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';

import classes from './ExFormElement.module.css';

const exFormElement = (props) => {
    let validationError = null;
    const inputClasses = [];

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
        if (props.units === 'kg') {
            validationError = <p style={{color: "red"}}>Please enter a valid weight</p>
        }
        else {
            validationError = <p style={{color: "red"}}>Please enter a valid number</p>
        }
        
    }


    return (
        <div className={classes.ExFormElement}>
            <label className={classes.Label}>{props.label}</label>
            <InputGroup className={inputClasses.join(' ')} size={props.size}>
                <Input autoFocus={props.autofocus} placeholder={props.placeholder} type={props.elementType} value={props.value} onChange={props.changed}/>
                <InputGroupAddon className={classes.InputGroupAddon} addonType="append">{props.units}</InputGroupAddon>
            </InputGroup>
            {validationError}
        </div>
    );
};

export default exFormElement;