import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const quads = (props) => {
    let click = () => {props.clicked('quads')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('quads') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['quads'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1015 3174 c-10 -99 1 -580 16 -694 5 -47 21 -131 35 -188 29 -126
        127 -422 156 -473 12 -21 46 -57 77 -79 l56 -42 17 22 c10 13 26 48 35 79 22
        75 21 345 -2 469 -18 97 -82 293 -156 480 -42 105 -194 447 -219 491 -5 9 -11
        -16 -15 -65z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2082 3143 c-72 -156 -240 -565 -276 -674 -80 -238 -99 -534 -45
        -693 13 -39 29 -71 35 -73 6 -2 35 15 64 38 64 51 105 132 178 354 83 250 112
        467 112 836 0 205 -6 299 -20 299 -4 0 -25 -39 -48 -87z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M996 2173 c2 -65 9 -149 15 -188 20 -138 80 -345 100 -345 5 0 22 27
        39 60 l30 59 -86 258 c-48 142 -90 262 -94 266 -5 5 -6 -44 -4 -110z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2136 2248 c-7 -40 -111 -352 -144 -434 l-20 -50 27 -48 c44 -74 48
        -76 70 -25 55 124 92 321 90 481 -1 91 -13 130 -23 76z"/>
    </Aux>
);
}

export default quads;