import React from 'react';
import { NavLink } from 'react-router-dom';
import demoRag from '../../assets/images/demo_rag.JPG';
import demoGrad from '../../assets/images/demo_gradient.JPG';
import classes from './ColourDemo.module.css';

const colourDemo = (props) => (
    <NavLink to="/">
        <div className={classes.Demo}>
                <img src={demoRag} alt="RAG Colours Demo"/>
                <img src={demoGrad} alt="Gradient Colours Demo"/>
        </div>
            
    </NavLink>
);

export default colourDemo;