import React from 'react';
import { connect } from 'react-redux';

import classes from '../Back.module.css';

import Calves from '../../Muscles/Man/Back/Calves/Calves';
import Forearms from '../../Muscles/Man/Back/Forearms/Forearms';
import Glutes from '../../Muscles/Man/Back/Glutes/Glutes';
import Hamstrings from '../../Muscles/Man/Back/Hamstrings/Hamstrings';
import Lats from '../../Muscles/Man/Back/Lats/Lats';
import LowerBack from '../../Muscles/Man/Back/LowerBack/LowerBack';
import Rhomboid from '../../Muscles/Man/Back/Rhomboid/Rhomboid';
import Shoulders from '../../Muscles/Man/Back/Shoulders/Shoulders';
import Traps from '../../Muscles/Man/Back/Traps/Traps';
import Triceps from '../../Muscles/Man/Back/Triceps/Triceps';
import Obliques from '../../Muscles/Man/Back/Obliques/Obliques';
import Key from '../../UI/Key/Key';
import GradientKey from '../../UI/Key/GradientKey/GradientKey';


const back = (props) => {

  let key = null;
  if (props.colourType==='gradient') {
    key = <GradientKey gt={props.gt} rt={props.rt} clicked={props.keyClicked}/>;
  } else {
    key = <Key gt={props.gt} rt={props.rt} clicked={props.keyClicked}/>;
  }

    return (
        <div className={props.theme==='light' ? classes.Back : [classes.Back, classes.BackDark].join(' ')}>
        {key}
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            //  width="307.000000pt" height="594.000000pt"
            viewBox="0 0 340.000000 700.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g transform="translate(-50,723.000000) scale(0.100000,-0.100000)" stroke="none">
            <Calves clicked={props.clicked} colours={props.colours} />
            <Forearms colours={props.colours} />
            <Glutes clicked={props.clicked} colours={props.colours} />
            <Hamstrings clicked={props.clicked} colours={props.colours} />
            <Lats clicked={props.clicked} colours={props.colours} />
            <LowerBack clicked={props.clicked} colours={props.colours} />
            <Rhomboid clicked={props.clicked} colours={props.colours} />
            <Shoulders clicked={props.clicked} colours={props.colours}/>
            <Traps clicked={props.clicked} colours={props.colours} />
            <Triceps clicked={props.clicked} colours={props.colours} />
            <Obliques clicked={props.clicked} colours={props.colours}/>
            </g>
        </svg>
        </div>
    );
    
};

const mapStateToProps = state => {
    return {
      theme: state.auth.settings.theme,
      colourType: state.auth.settings.colourType
    };
  };
  
  
export default connect(mapStateToProps)(back);