import React from 'react';
import { connect } from 'react-redux';

import classes from './MuscleHistoryElement.module.css';
import date from 'date-and-time';




const muscleHistoryElement = (props) => {
    const weightTime = props.weight ? props.weight + ' kg': props.time + ' seconds';
    // const sets = props.sets === '1' ? 'set' : 'sets'
    const reps = props.reps === '1' ? 'rep' : 'reps'
    
    return (
    <div className={classes.MuscleHistoryElement} onClick={props.clicked}>
        
        <div className={props.theme==='dark' ? [classes.Left, classes.LeftDark].join(' ') : classes.Left}>
        <h6>
        {Math.round((new Date() - new Date(props.date))/3600/24/1000)}{Math.round((new Date() - new Date(props.date))/3600/24/1000)===1 ? ' day ago' : ' days ago'}
            <p style={{fontSize: ".5rem", paddingTop: "2px", margin: "0px"}}>{date.format(new Date(props.date), 'D MMM')}</p>
        </h6>
            <h5>{props.name}</h5>
        </div>

        <div className={classes.Right}>
            {/* <h5>{props.sets} {sets}</h5> */}
            <h5>{props.sets} x {props.reps} {reps}</h5>
            <h5><strong>{weightTime}</strong></h5>
        </div>

    </div>
    );
};

const mapStateToProps = state => {
    return {
      theme: state.auth.settings.theme
    };
  };
  
  
export default connect(mapStateToProps)(muscleHistoryElement);