import axios from '../../axios';
import { auth } from '../../firebase';
import * as actionTypes from './actionTypes';
import { exerciseList } from '../../shared/staticLists';
import { combineExerciseLists } from '../../shared/utility';



// fullbody actions that need authorisation before they can be dispatched

export const fetchExerciseLogStart = () => {
    return {
        type: actionTypes.FETCH_EXERCISE_LOG_START
    };
};

export const fetchExerciseLogSuccess = (exerciseLog) => {
    return {
        type: actionTypes.FETCH_EXERCISE_LOG_SUCCESS,
        exerciseLog: exerciseLog
    };
};

export const fetchExerciseLogFail = (error) => {
    return {
        type: actionTypes.FETCH_EXERCISE_LOG_FAIL,
        error: error
    };
};

export const fetchExerciseLog = (token, userId, greenTime, redTime, goodColour, badColour) => {
    return dispatch => {
        dispatch(fetchExerciseLogStart());
        axios.get('/users/' + userId + '/exerciseLog.json?auth=' + token)
        .then(response => {
            dispatch(fetchExerciseLogSuccess(response.data));
            dispatch(getCustomExercisesAndConsolidate(token, userId, response.data, greenTime, redTime, goodColour, badColour));
            // dispatch(calculateMuscleRAGs(response.data, greenTime, redTime));
        }).catch(err => {
            dispatch(fetchExerciseLogFail(err));
            console.log(err);
        });
    }
}

export const getCustomExercisesAndConsolidate = (token, userId, exerciseLog, greenTime, redTime, goodColour, badColour) => {
    return dispatch => {
        axios.get('/users/' + userId + '/customExercises.json?auth=' + token)
        .then(response => {
            if (response.data === null) {
                // no custom exercise list found for user, so pass in standard list to rag calcs and state updating (for displaying exercises)
                dispatch(putExerciseListInState(exerciseList));
                dispatch(calculateMuscleRAGs(exerciseLog, exerciseList, greenTime, redTime));
                dispatch(calculateMuscleGradients(exerciseLog, exerciseList, greenTime, redTime, goodColour, badColour));
            } else {
                const allExercisesUnique = combineExerciseLists(exerciseList, response.data);
                dispatch(putExerciseListInState(allExercisesUnique));
                dispatch(calculateMuscleRAGs(exerciseLog, allExercisesUnique, greenTime, redTime));
                dispatch(calculateMuscleGradients(exerciseLog, allExercisesUnique, greenTime, redTime, goodColour, badColour));
            } 
        })
        .catch(err => {
            console.log(err);
            dispatch(fetchExerciseLogFail(err));
        });
    };
};

export const putExerciseListInState = (exerciseList) => {
    return {
        type: actionTypes.PUT_EXERCISE_LIST_IN_STATE,
        exerciseList: exerciseList
    };
};

export const calculateMuscleRAGs = (exerciseLog, exerciseList, greenTime, redTime) => {
    return {
        type: actionTypes.CALCULATE_MUSCLE_RAGS,
        exerciseLog: exerciseLog,
        exerciseList: exerciseList,
        greenTime: greenTime,
        redTime: redTime
    };
};

export const calculateMuscleGradients = (exerciseLog, exerciseList, greenTime, redTime, goodColour, badColour) => {
    return {
        type: actionTypes.CALCULATE_MUSCLE_GRADIENTS,
        exerciseLog: exerciseLog,
        exerciseList: exerciseList,
        greenTime: greenTime,
        redTime: redTime,
        goodColour: goodColour,
        badColour: badColour
    };
};

export const signInSuccess = (token, userId) => {
    return {
        type: actionTypes.SIGN_IN_SUCCESS,
        token: token,
        userId: userId       
    };
};

export const signIn = (token, userId) => {
    return dispatch => {
        dispatch(signInSuccess(token, userId));
        dispatch(getSettings(token, userId));
    };
};

export const signOut = () => {
    auth.signOut()
    return {
        type: actionTypes.SIGNOUT
    };
};


// GENERIC SETTINGS ACTIONS

export const updateSettings = (token, userId, settings) => {
    
    return dispatch => {
        // console.log('dispatching updatesettingsStart, settings of: ', settings);
        dispatch(updateSettingsStart());
        axios.patch('/users/' + userId + '/settings.json?auth=' + token, settings)
        .then(response => {
            dispatch(updateSettingsSuccess(response.data));
            dispatch(getSettings(token, userId));
        })
        .catch(error => {
            dispatch(updateSettingsFail(error));
        });
    };
};

export const getSettings = (token, userId) => {
    return dispatch => {
        // console.log('getSettingsStart triggered');
        dispatch(getSettingsStart());
        axios.get('/users/' + userId + '/settings.json?auth=' + token)
        .then(response => {
            // console.log('response back for getSettings, looks like: ', response.data);
            if (response.data === null) {
                // no settings data, therefore user must choose gender before we need to look at exercise log
                // console.log('1');
                dispatch(getSettingsSuccess(response.data, false));
            } else {
                // console.log('2');
                dispatch(getSettingsSuccess(response.data, true));
                dispatch(fetchExerciseLog(token, userId, response.data.greenTime, response.data.redTime, response.data.goodColour, response.data.badColour));
            } 
        }).catch(err => {
            dispatch(getSettingsFail(err));
        });
    };
};

export const updateSettingsStart = () => {
    return {
        type: actionTypes.UPDATE_SETTINGS_START
    };
};
export const updateSettingsSuccess = () => {
    return {
        type: actionTypes.UPDATE_SETTINGS_SUCCESS,
    };
};
export const updateSettingsFail = (error) => {
    return {
        type: actionTypes.UPDATE_SETTINGS_FAIL,
        error: error
    };
};

export const getSettingsStart = () => {
    return {
        type: actionTypes.GET_SETTINGS_START
    };
};
export const getSettingsSuccess = (settings, loading) => {
    return {
        type: actionTypes.GET_SETTINGS_SUCCESS,
        settings: settings,
        loading: loading
    };
};
export const getSettingsFail = (error) => {
    return {
        type: actionTypes.GET_SETTINGS_FAIL,
        error: error
    };
};