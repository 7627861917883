import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

// Configure Firebase.
const config = {
    apiKey: process.env.REACT_APP_API_KEY_UNRESTRICTED,
    authDomain: "rag-fit.firebaseapp.com",
    databaseURL: "https://rag-fit.firebaseio.com",
    projectId: "rag-fit",
    storageBucket: "rag-fit.appspot.com",
    messagingSenderId: "794555174014",
    appId: "1:794555174014:web:f6fa619875d81708261c2f",
    measurementId: "G-YNNBKMY27D"
  };

  firebase.initializeApp(config);
  firebase.analytics();

  export const auth = firebase.auth();