import React from 'react';

import classes from '../../../../Muscles/Muscles.module.css';


const neck = (props) => {

    let click = null;
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('neck') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['neck'];
    }
    return (
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1451 5783 c-18 -67 -17 -77 32 -183 l49 -105 44 4 45 3 49 104 c27
        58 47 110 45 117 -2 6 -9 31 -15 55 l-11 42 -114 0 -114 0 -10 -37z"/>
    );

 
};

export default neck;