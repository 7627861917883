import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';


const forearms = (props) => {
    let click = null;
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('forearms') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['forearms'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M300 4313 c-34 -90 -86 -307 -104 -428 -24 -168 -41 -435 -30 -468 9
        -28 11 -26 53 60 65 130 140 308 157 372 22 77 29 231 15 321 -12 79 -50 200
        -62 200 -4 0 -17 -26 -29 -57z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2786 4261 c-60 -243 -31 -405 131 -744 l68 -141 5 84 c13 220 -46
        616 -121 812 -46 118 -52 117 -83 -11z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M526 4057 c-46 -60 -95 -181 -155 -382 -32 -110 -64 -222 -69 -250
        l-10 -50 19 25 c70 85 160 277 209 445 29 101 51 245 37 245 -3 0 -17 -15 -31
        -33z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2590 4063 c0 -78 90 -363 155 -494 40 -79 108 -189 118 -189 14 0 0
        51 -85 315 -82 256 -102 306 -145 363 -29 39 -43 41 -43 5z"/>
    </Aux>
    );
};

export default forearms;