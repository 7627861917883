import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';


const abs = (props) => {
    let click = () => {props.clicked('abs')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('abs') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['abs'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1377 4617 c-53 -17 -101 -59 -126 -110 -43 -90 -18 -146 67 -148 96
        -3 158 3 179 16 33 22 57 96 50 159 -8 85 -18 96 -80 95 -29 0 -69 -6 -90 -12z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1630 4615 c-11 -13 -15 -43 -15 -107 0 -88 1 -90 32 -118 25 -22 45
        -30 98 -35 77 -9 139 -2 161 16 25 20 15 125 -15 167 -49 69 -225 120 -261 77z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1300 4291 c-35 -10 -62 -62 -63 -121 -1 -98 32 -120 184 -120 107 0
        121 11 127 102 6 75 -15 122 -61 137 -33 12 -148 13 -187 2z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1672 4293 c-13 -3 -32 -19 -43 -36 -25 -40 -23 -140 5 -181 l18 -28
        109 4 c133 4 148 13 156 104 4 51 2 64 -19 94 -13 19 -34 37 -48 41 -27 8
        -142 9 -178 2z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1303 3973 c-26 -5 -53 -58 -53 -104 0 -54 38 -119 78 -136 45 -19
        177 -22 194 -5 8 8 14 46 16 91 5 99 -11 135 -66 150 -36 11 -125 13 -169 4z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1670 3973 c-33 -9 -55 -56 -58 -128 -6 -126 21 -149 155 -125 120
        20 173 107 128 212 -14 34 -16 35 -82 41 -73 7 -119 7 -143 0z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1735 3650 c-75 -11 -91 -18 -105 -48 -18 -38 -26 -279 -10 -326 10
        -33 15 -36 48 -36 72 0 110 18 137 64 51 87 83 289 53 328 -17 20 -60 26 -123
        18z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1294 3615 c-15 -83 13 -241 56 -310 34 -56 132 -88 171 -57 19 16
        22 30 27 134 6 141 -2 200 -32 235 -22 25 -30 27 -119 31 l-96 4 -7 -37z"/>
    </Aux>
    );
};

export default abs;