import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const calves = (props) => {

    let click = () => {props.clicked('calves')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('calves') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['calves'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M1617 1792 c-30 -33 -37 -120 -37 -471 0 -375 11 -623 35 -796 18
            -129 26 -164 40 -166 11 -1 42 88 76 219 12 46 25 80 29 75 4 -4 22 -53 40
            -108 37 -113 89 -235 100 -235 21 0 86 335 100 515 13 161 12 684 0 764 -12
            69 -52 159 -76 168 -23 9 -72 -44 -110 -119 -19 -38 -38 -68 -42 -68 -4 0 -18
            31 -31 70 -29 84 -77 170 -95 170 -7 0 -20 -8 -29 -18z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill}
            d="M2676 1758 c-21 -37 -37 -73 -82 -198 -4 -9 -20 13 -44 60 -42 82
            -90 136 -114 127 -25 -10 -65 -102 -76 -174 -13 -88 -13 -603 0 -758 15 -186
            79 -515 100 -515 10 0 71 145 105 250 15 47 31 89 35 93 4 5 17 -29 29 -75 75
            -286 83 -289 116 -55 25 179 37 481 33 857 -3 337 -4 357 -24 390 -29 50 -48
            49 -78 -2z"/>
            
            
    </Aux>
);
}

export default calves;