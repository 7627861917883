import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const pecs = (props) => {

    let click = () => {props.clicked('pecs')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('pecs') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['pecs'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M820 4513 c-56 -9 -70 -14 -101 -40 -60 -51 -82 -139 -60 -237 14 -56 24 -62 61 -35 27 21 43 24 113 23 152 -1 256 -52 311 -153 18 -35 32 -49 38 -43 6 6 8 67 6 153 -4 164 -23 225 -84 279 -54 47 -179 71 -284 53z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1397 4506 c-84 -23 -134 -75 -162 -169 -17 -57 -22 -317 -6 -317 6 0 22 23 38 52 54 100 158 151 310 152 70 1 86 -2 112 -22 37 -27 47 -23 61 26 31 113 -7 224 -93 269 -39 20 -199 25 -260 9z"/>
    </Aux>
    );
}

export default pecs;