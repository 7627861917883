import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const hamstrings = (props) => {
    let click = () => {props.clicked('hamstrings')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('hamstrings') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['hamstrings'];
    }

    return (
    <Aux>

        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M899 2812 c-52 -43 -64 -98 -64 -292 0 -110 6 -198 14 -238 20 -90 8 -375 -22 -557 l-24 -140 41 46 c23 25 70 70 105 100 65 55 121 146 121 197 0 44 18 21 30 -40 15 -74 28 -102 85 -183 23 -33 57 -87 76 -120 l34 -60 6 130 c3 72 18 211 32 310 36 245 47 374 54 615 7 276 30 253 -248 254 -208 1 -214 0 -240 -22z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1643 2812 c-23 -23 -25 -57 -20 -245 6 -226 21 -391 54 -617 14 -96 28 -233 32 -305 l6 -130 34 60 c19 33 53 87 76 120 57 81 70 109 85 182 7 35 17 60 21 57 5 -3 9 -13 9 -22 0 -9 13 -44 29 -77 20 -44 45 -75 92 -114 35 -30 82 -75 105 -100 l41 -46 -23 140 c-32 185 -42 445 -23 552 8 47 14 146 14 248 0 186 -11 239 -60 285 -25 23 -29 24 -242 24 -167 1 -220 -2 -230 -12z"/>
    </Aux>
);
}

export default hamstrings;