import React from 'react';


import classes from './Button.module.css';

const button = (props) => (
<button style={props.style}
    disabled={props.disabled}
    onClick={props.clicked}
    className={[classes.Button, classes[props.btnColor], classes[props.btnSize]].join(' ')}
>
    {props.children}
</button>
);

export default button;