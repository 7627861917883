import React from 'react';

import classes from './SideDrawer.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import Backdrop from '../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import ShareButton from '../../../assets/images/share-button.png';
import GooglePlayBadge from '../../../assets/images/google-play-badge.png';
// import { Button } from 'reactstrap';


const sideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }

    let appInstallPrompt = null;
    if (props.showInstallPrompt) {
        appInstallPrompt = (
            <Aux>
                <hr />
                <div className={classes.InstallApp}>
                Track your workouts more easily with our free app!
                    <div style={{paddingTop: "5px"}}>
                        <button className={[classes.InstallButton, classes.InstallDismiss].join(' ')} onClick={props.dismissed}>Not now</button>
                        <div className={classes.Divider}/>
                        <button className={classes.InstallButton} onClick={props.accepted}>Install</button>
                    </div>
                </div>
                <p>
                    <a href="https://play.google.com/store/apps/details?id=com.ragfitapp.ragfitapp">
                        <img src={GooglePlayBadge} width="100%" alt="google play badge"></img>
                    </a>
                </p>
            </Aux>
        );
    }

    let appInstallInstructions = null;
    if (props.showInstallInstruct && !props.showInstallPrompt) {
        appInstallInstructions = (
            <Aux>
                <hr />
                <div className={classes.InstallApp}>
                Install this app on your device: Tap &nbsp;<img src={ShareButton} width="10px" alt="Share-button"/> &nbsp;at the bottom of your screen and then "Add to homescreen"
                </div>

            </Aux>            
        );
    }
    
    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ')} onClick={props.closed}>
                <div className={classes.Logo}>
                    <Logo />
                </div>
                <nav>
                    <NavigationItems isAuthenticated={props.isAuth}/>
                </nav>
                
                {appInstallPrompt}
                {appInstallInstructions}
            </div>
        </Aux>
    );
};

export default sideDrawer;