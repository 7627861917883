import React, { Component } from 'react';

import classes from './MuscleHistory.module.css';
import MuscleHistoryElement from './MuscleHistoryElement/MuscleHistoryElement';
import { Button } from 'reactstrap'; 
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import date from 'date-and-time';

class MuscleHistory extends Component {

    state = {
        confirmingDelete: false,
        idToDelete: null,
        exerciseName: null,
        exerciseDate: null
    }

    compare = (a, b) => {
        const dateA = a.exerciseDate
        const dateB = b.exerciseDate
      
        let comparison = 0;
        if (dateA < dateB) {
          comparison = 1;
        } else if (dateA > dateB) {
          comparison = -1;
        }
        return comparison;
    }

    confirmDeleteHandler = (id, name, date) => {
        this.setState({confirmingDelete: true, idToDelete: id, exerciseDate: date, exerciseName: name})
    }

    actuallyDeleteHandler = () => {
        this.props.delete(this.state.idToDelete);
        this.setState({confirmingDelete: false, idToDelete: null, exerciseDate: null, exerciseName: null});
    }

    cancelDeleteHandler = () => {
        this.setState({confirmingDelete: false, idToDelete: null, exerciseDate: null, exerciseName: null});
    }

    render () {

        let confirmDelete = null;
        if (this.state.confirmingDelete) {
            confirmDelete = (
                <Aux>
                    <br />
                    <h6><b><i>Are you sure you want to delete this exercise? </i></b></h6>
                    <p className={classes.Subtitle}>{date.format(new Date(this.state.exerciseDate), 'DD/MM/YY')}</p>
                    <p className={classes.Subtitle}>{this.state.exerciseName}</p> 
                    <br />
                    <Button color="secondary" block size="lg" onClick={this.cancelDeleteHandler}>Cancel</Button>
                    <Button color="danger" block size="lg" onClick={this.actuallyDeleteHandler}>Delete</Button>

                </Aux>
            );
        }

        let filteredLog = [];
        const log = this.props.log ? this.props.log : {};
        
        for (const [key, val] of Object.entries(log)) {
            // make sure exercise in log is current exerciseList
            if (val.exerciseName in this.props.list) {
                if (this.props.list[val.exerciseName].indexOf(this.props.muscle) >= 0) {
                    val['id'] = key;
                    filteredLog.push(val);
                }
            }
        }

        let history = null;
        if (!this.state.confirmingDelete) {
            history = filteredLog.sort(this.compare).map(arrEl => {
                return (
                    <MuscleHistoryElement
                        key={arrEl.exerciseDate}
                        date={arrEl.exerciseDate}
                        name={arrEl.exerciseName}
                        sets={arrEl.exerciseData.sets}
                        reps={arrEl.exerciseData.reps}
                        weight={arrEl.exerciseData.kg}
                        clicked={() => this.confirmDeleteHandler(arrEl.id, arrEl.exerciseName, arrEl.exerciseDate)}
                        time={arrEl.exerciseData['time (s)']}
                    />
                );
            });    
        }    
            
            return (
                <div className={classes.MuscleHistory}>
                {/* <h3>Workout History</h3>
                <h6 className={classes.Caption}>(Days since workout shown in brackets)</h6>
                <h6 className={classes.Delete}>Click on a workout to delete.</h6> */}
                {history}
                {confirmDelete}
                </div>
            );
        };
};



export default MuscleHistory;