import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Button, ButtonToggle } from 'reactstrap';

import Spinner from '../../components/UI/Spinner/Spinner';
import classes from './InitialSettings.module.css';
import * as actions from '../../store/actions/index';
import ColourDemo from '../../components/ColourDemo/ColourDemo';

class InitialSettings extends Component {
    state = {
        settingsForm: {
            gender: 'male',
            theme:  'light',
            colourType: 'rag',

        },
        loading: false
    }
    
    updateFormHandler = (key, value) => {
        const updatedForm = {...this.state.settingsForm};
        updatedForm[key] = value;
        this.setState({settingsForm: updatedForm});
    }

    initialiseSettingsHandler = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        // set gender, and while we're initialising settings, initialise defaults for redtime, greentime & theme
        
        const initialSettings = {
            redTime: 7,
            greenTime: 1,
            theme: this.state.settingsForm.theme,
            gender: this.state.settingsForm.gender,
            colourType: this.state.settingsForm.colourType,
            goodColour: "rgb(9, 96, 112)",
            badColour: "rgb(155, 219, 241)"
        }

        this.props.onUpdateSettings(this.props.token, this.props.userId, initialSettings);
        this.setState({loading: false});
    }

    render () {

        let form = <Spinner />;
        if (!this.state.loading) {
            form = (
                <form onSubmit={this.initialiseSettingsHandler}>
                    <p className={classes.Label}>Body Type</p>
                    <p className={classes.Sublabel}>This will determine the body type your muscle status is visualised on</p>
                        <ButtonToggle 
                        outline={this.state.settingsForm.gender === 'male' ? false : true}
                        style={{width:"49%"}} 
                        color="primary"
                        onClick={() => this.updateFormHandler('gender', 'male')}
                        >Male
                        </ButtonToggle>{' '}
                        <ButtonToggle 
                        outline={this.state.settingsForm.gender === 'female' ? false : true}
                        style={{width:"49%"}} 
                        onClick={() => this.updateFormHandler('gender', 'female')}
                        color="primary">Female</ButtonToggle>{' '}
                    <hr />
                    <p className={classes.Label}>Theme</p>
                    <p className={classes.Sublabel}>Would you prefer a light or dark theme for the RagFit app?</p>
                        <ButtonToggle 
                        outline={this.state.settingsForm.theme === 'light' ? false : true}
                        style={{width:"49%"}} 
                        color="info"
                        onClick={() => this.updateFormHandler('theme', 'light')}
                        >Light
                        </ButtonToggle>{' '}
                        <ButtonToggle
                        outline={this.state.settingsForm.theme === 'dark' ? false : true}
                        style={{width:"49%"}} 
                        onClick={() => this.updateFormHandler('theme', 'dark')}
                        color="secondary">Dark</ButtonToggle>{' '}                
                    <hr />
                    <p className={classes.Label}>Colour Scheme</p>
                    <p className={classes.Sublabel}>Would you prefer RAG (red, amber &amp; green) or monochrome for your muscle status visualisation? You can specify the colour in settings</p>
                        <ButtonToggle 
                        outline={this.state.settingsForm.colourType === 'rag' ? false : true}
                        style={{width:"49%"}} 
                        color="warning"
                        onClick={() => this.updateFormHandler('colourType', 'rag')}
                        >RAG
                        </ButtonToggle>{' '}
                        <ButtonToggle 
                        outline={this.state.settingsForm.colourType === 'gradient' ? false : true}
                        style={{width:"49%"}} 
                        onClick={() => this.updateFormHandler('colourType', 'gradient')}
                        color="info">Gradient</ButtonToggle>{' '}  
                        <ColourDemo />
                    <hr />
                    <Button color="success">Continue</Button>
                </form>              
            );
        }

        return (

            <div className={classes.Settings}>
                <h3>Choose Preferences</h3>
                <p className={classes.Subtitle}>You can change these at any time in the settings menu</p>
                {form}
            </div>


        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        greenTime: state.auth.settings.greenTime,
        redTime: state.auth.settings.redTime,
        gender: state.auth.settings.gender,
        theme: state.auth.settings.theme,
        loading: state.auth.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateSettings: (token, userId, settings) => dispatch(actions.updateSettings(token, userId, settings))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(InitialSettings);