import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const abductors = (props) => {

    let click = () => {props.clicked('abductors')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('abductors') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['abductors'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2197 3078 c6 -141 -10 -497 -27 -633 -5 -38 -7 -72 -4 -74 11 -12
        56 49 74 101 66 189 68 576 3 703 -15 29 -31 45 -44 45 -4 0 -5 -64 -2 -142z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M911 3173 c-34 -69 -46 -150 -45 -323 1 -127 6 -187 22 -262 23 -112
        61 -201 91 -217 24 -14 24 -18 11 84 -21 172 -30 330 -31 538 -1 177 -3 217
        -15 217 -7 0 -22 -17 -33 -37z"/>
    </Aux>
    );
};

export default abductors;