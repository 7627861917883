// Note: implementing signup/signin, but not refresh token for now - by default these will expire in 60 mins, need to implement refreshtoken to fix

import * as actionTypes from '../actions/actionTypes';
import { calculateRAGs, calculateGradients, updateObject } from '../../shared/utility';


const initialState = {
    token: null,
    userId: null,
    isSignedIn: false,
    exerciseLog: null,
    muscleRAGs: null,
    muscleGradients: null,
    error: null,
    loading: false,
    settings: {
        redTime: null,
        greenTime: null,
        gender: null,
        theme: null,
        colourType: null,
        goodColour: null,
        badColour: null
    },
    exerciseList: {}
};


const fetchExerciseLogStart = (state, action) => {
    return updateObject(state, {error: null, loading: true });
};

const fetchExerciseLogSuccess = (state, action) => {
    return updateObject(state, {
        error: null, 
        exerciseLog: action.exerciseLog,
    });
};

const fetchExerciseLogFail = (state, action) => {
    return updateObject(state, {error: action.error, loading: false});
};

const putExerciseListInState = (state, action) => {
    let updatedState = {...state};
    const updatedExerciseList = {...updatedState.exerciseList, ...action.exerciseList};
    updatedState.exerciseList = updatedExerciseList;
    return updatedState;
};

const calculateMuscleRAGs = (state, action) => {
    const rags = calculateRAGs(action.exerciseLog, action.exerciseList, action.greenTime, action.redTime);
    return updateObject(state, {muscleRAGs: rags});
};

const calculateMuscleGradients = (state, action) => {
    const grads = calculateGradients(action.exerciseLog, action.exerciseList, action.greenTime, action.redTime, action.goodColour, action.badColour);
    return updateObject(state, {muscleGradients: grads, loading: false});
};

const signInSuccess = (state, action) => {
    return updateObject(state, {
        isSignedIn: true,
        token: action.token,
        userId: action.userId
    });
};

const signOut = (state, action) => {
    return updateObject(state, {token: null, userId: null, isSignedIn: false, exerciseLog: null, muscleRAGs: null });
};


export const updateSettingsStart = (state, action) => {
    return updateObject(state, {loading: true});
};

export const updateSettingsSuccess = (state, action) => {
    return state;
};

export const updateSettingsFail = (state, action) => {
    console.log('[updateSettingsFail]', action.error);
    return updateObject(state, {loading: false, error: action.error});
};

export const getSettingsStart = (state, action) => {
    return updateObject(state, {loading: true});
};

export const getSettingsSuccess = (state, action) => {
    const oldState = {...state, loading: action.loading};
    const updatedSettings = {...oldState.settings, ...action.settings};
    return {...oldState, settings: updatedSettings};
};

export const getSettingsFail = (state, action) => {
    console.log('[getSettingsFail]', action.error);
    return updateObject(state, {loading: false, error: action.error});
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGNOUT:
            return signOut(state, action);
        case actionTypes.FETCH_EXERCISE_LOG_START:
            return fetchExerciseLogStart(state, action);
        case actionTypes.FETCH_EXERCISE_LOG_SUCCESS:
            return fetchExerciseLogSuccess(state, action);
        case actionTypes.FETCH_EXERCISE_LOG_FAIL:
            return fetchExerciseLogFail(state, action);
        case actionTypes.PUT_EXERCISE_LIST_IN_STATE:
            return putExerciseListInState(state, action);
        case actionTypes.CALCULATE_MUSCLE_RAGS:
            return calculateMuscleRAGs(state, action);
        case actionTypes.CALCULATE_MUSCLE_GRADIENTS:
            return calculateMuscleGradients(state, action);
        case actionTypes.SIGN_IN_SUCCESS:
            return signInSuccess(state, action);
        case actionTypes.UPDATE_SETTINGS_START:
            return updateSettingsStart(state, action);
        case actionTypes.UPDATE_SETTINGS_SUCCESS:
            return updateSettingsSuccess(state, action);
        case actionTypes.UPDATE_SETTINGS_FAIL:
            return updateSettingsFail(state, action);
        case actionTypes.GET_SETTINGS_START:
            return getSettingsStart(state, action);
        case actionTypes.GET_SETTINGS_SUCCESS:
            return getSettingsSuccess(state, action);
        case actionTypes.GET_SETTINGS_FAIL:
            return getSettingsFail(state, action);
        default: 
            return state;
    }
}

export default reducer;