import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const abductors = (props) => {

    let click = () => {props.clicked('abductors')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('abductors') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['abductors'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M581 2387 c-17 -43 -23 -132 -12 -178 15 -68 90 -274 107 -296 13 -16 14 -13 9 37 -11 95 -56 383 -66 423 -11 42 -25 48 -38 14z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1785 2400 c-16 -26 -90 -489 -79 -499 8 -8 14 5 55 115 54 141 69 197 69 259 0 80 -27 155 -45 125z"/>
    </Aux>
    );
};

export default abductors;