import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const triceps = (props) => {

    let click = () => {props.clicked('triceps')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('triceps') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['triceps'];
    }

    return (
    <Aux>

        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M1132 5833 c-149 -181 -210 -341 -199 -528 5 -95 45 -283 68 -327 10
            -18 12 -19 29 -3 27 24 108 214 141 327 35 120 49 228 49 370 0 120 -15 208
            -35 208 -7 0 -31 -21 -53 -47z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}  
            d="M3149 5813 c-15 -74 -6 -332 15 -428 22 -102 58 -207 108 -315 51
            -109 71 -132 87 -101 24 43 63 231 68 326 11 187 -50 347 -199 528 -22 26 -46
            47 -53 47 -8 0 -18 -22 -26 -57z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}        
            d="M903 5685 c-52 -63 -130 -232 -148 -317 -22 -114 -15 -292 13 -320
            32 -32 64 66 82 248 6 67 26 168 50 259 22 82 39 153 37 158 -2 6 -17 -7 -34
            -28z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}         
            d="M3420 5706 c0 -5 18 -77 40 -160 22 -84 44 -197 50 -251 16 -161 21
            -188 39 -224 38 -80 60 -37 68 129 7 143 -8 206 -80 343 -46 89 -116 186 -117
            163z"/>
    </Aux>
);
}

export default triceps;