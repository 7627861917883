import React from 'react';
import { connect } from 'react-redux';


import classes from '../Front.module.css';

import Traps from '../../Muscles/Woman/Front/Traps/Traps';
import Shoulders from '../../Muscles/Woman/Front/Shoulders/Shoulders'; 
import Pecs from '../../Muscles/Woman/Front/Pecs/Pecs'; 
import Biceps from '../../Muscles/Woman/Front/Biceps/Biceps'; 
import Obliques from '../../Muscles/Woman/Front/Obliques/Obliques'; 
import Abs from '../../Muscles/Woman/Front/Abs/Abs'; 
import Forearms from '../../Muscles/Woman/Front/Forearms/Forearms'; 
import Adductors from '../../Muscles/Woman/Front/Adductors/Adductors';
import Quads from '../../Muscles/Woman/Front/Quads/Quads';
import Abductors from '../../Muscles/Woman/Front/Abductors/Abductors';
import Shins from '../../Muscles/Woman/Front/Shins/Shins';
import Pattern from '../../UI/Patterns/Pattern';
import Key from '../../UI/Key/Key';
import GradientKey from '../../UI/Key/GradientKey/GradientKey';

const front = (props) => {
  
  let key = null;
  if (props.colourType==='gradient') {
    key = <GradientKey gt={props.gt} rt={props.rt} clicked={props.keyClicked}/>;
  } else {
    key = <Key gt={props.gt} rt={props.rt} clicked={props.keyClicked}/>;
  }    

    return (
        <div className={props.theme==='light' ? classes.Front : [classes.Front, classes.FrontDark].join(' ')}>
        {key}
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 275 550" preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g transform="translate(20,540) scale(0.100000,-0.100000)">
            <Pattern />
            <Traps clicked={props.clicked} colours={props.colours} />
            <Shoulders clicked={props.clicked} colours={props.colours} /> 
            <Pecs clicked={props.clicked} colours={props.colours} />
            <Biceps clicked={props.clicked} colours={props.colours} />
            <Obliques clicked={props.clicked} colours={props.colours} />
            <Abs clicked={props.clicked} colours={props.colours} />
            <Forearms  colours={props.colours}/>
            <Adductors clicked={props.clicked} colours={props.colours} />
            <Quads clicked={props.clicked} colours={props.colours} />
            <Abductors clicked={props.clicked} colours={props.colours} />
            <Shins colours={props.colours} />
            </g>
        </svg>
        </div>
    );
    
};

const mapStateToProps = state => {
    return {
      theme: state.auth.settings.theme,
      colourType: state.auth.settings.colourType
    };
  };
  
  
export default connect(mapStateToProps)(front);