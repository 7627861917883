import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const biceps = (props) => {
    let click = () => {props.clicked('biceps')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('biceps') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['biceps'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M661 5055 c-66 -31 -189 -152 -218 -215 -11 -25 -28 -78 -38 -117
        -29 -121 -12 -342 37 -465 9 -26 12 -26 31 -13 12 8 52 52 90 97 138 165 228
        410 231 627 l1 106 -40 3 c-26 1 -58 -6 -94 -23z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2360 4980 c0 -121 24 -239 75 -365 59 -146 126 -249 229 -348 34
        -34 41 -36 49 -22 29 52 60 262 52 355 -17 210 -42 264 -173 376 -83 71 -146
        104 -197 104 l-35 0 0 -100z"/>
    </Aux>
    );
};

export default biceps;