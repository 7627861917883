import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const forearms = (props) => {
    let click = null;
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('forearms') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['forearms'];
    }

    return (
    <Aux>

        <path 
        onClick={click}
        className={classname}
        fill={fill}  
        d="M276 4232 c-30 -49 -108 -411 -120 -559 -7 -81 4 -95 23 -30 7 23 21 60 31 82 18 40 88 237 111 309 6 22 12 63 12 91 0 68 -37 139 -57 107z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2694 4198 c-22 -43 -20 -126 5 -203 36 -113 93 -270 101 -280 4 -5 16 -36 26 -67 10 -32 21 -58 26 -58 10 0 10 -6 -2 110 -23 214 -101 530 -131 530 -5 0 -16 -15 -25 -32z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}         
        d="M401 4105 c-16 -25 -58 -120 -94 -212 -179 -455 -166 -417 -143 -426 19 -7 22 -3 180 226 148 215 166 255 152 347 -7 48 -39 110 -56 110 -6 0 -23 -20 -39 -45z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}         
        d="M2540 4108 c-38 -63 -38 -165 0 -236 11 -21 77 -120 148 -222 92 -133 134 -186 150 -188 12 -2 22 2 22 8 0 7 -21 64 -46 128 -25 65 -59 153 -76 197 -93 240 -144 345 -168 345 -6 0 -19 -14 -30 -32z"/>
    </Aux>
);
}

export default forearms;