import React from 'react';
import { connect } from 'react-redux';

import classes from './Spinner.module.css';

const spinner = (props) => (
    <div className={props.theme==='dark' ? [classes.Loader, classes.LoaderDark].join(' ') : classes.Loader}>Loading...</div>
);

const mapStateToProps = state => {
    return {
      theme: state.auth.settings.theme
    };
  };
  
  
export default connect(mapStateToProps)(spinner);