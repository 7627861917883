import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const glutes = (props) => {

    let click = () => {props.clicked('glutes')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('glutes') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['glutes'];
    }


    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}  
            d="M1802 4353 c-13 -9 -41 -42 -61 -72 -115 -171 -177 -337 -192 -519
            -10 -117 -5 -267 10 -291 7 -10 31 -1 102 37 52 28 135 67 184 87 193 78 228
            104 272 198 25 55 28 74 28 157 0 89 -3 100 -38 172 -42 86 -114 167 -192 217
            -55 35 -79 38 -113 14z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M2445 4329 c-183 -116 -273 -328 -216 -507 39 -120 69 -145 289 -238
            59 -25 145 -66 191 -91 64 -35 85 -42 91 -32 16 25 22 175 12 294 -15 171 -67
            319 -167 476 -53 82 -100 129 -130 129 -11 0 -43 -14 -70 -31z"/>
            
    </Aux>
);
}

export default glutes;