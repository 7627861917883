import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const calves = (props) => {

    let click = () => {props.clicked('calves')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('calves') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['calves'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M828 1440 c-53 -41 -61 -84 -59 -321 1 -119 8 -270 16 -335 24 -201 73 -517 85 -551 24 -66 76 -28 115 83 14 38 25 77 25 86 0 43 19 21 34 -40 22 -86 61 -172 79 -172 23 0 34 40 86 312 70 368 81 449 81 605 0 127 -2 142 -26 193 -32 70 -69 108 -111 116 -43 8 -74 -12 -109 -71 l-26 -45 -24 47 c-33 64 -43 77 -81 96 -41 22 -55 21 -85 -3z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2094 1431 c-34 -21 -67 -63 -88 -111 l-13 -32 -27 46 c-35 60 -66 80 -109 72 -39 -7 -75 -43 -108 -106 -21 -39 -24 -62 -27 -182 -5 -165 3 -225 74 -600 56 -297 67 -338 91 -338 17 0 57 84 77 164 17 64 36 95 36 59 0 -40 41 -152 66 -183 32 -37 58 -39 73 -2 11 27 58 329 86 554 19 154 23 518 6 577 -14 49 -59 101 -88 101 -10 0 -32 -9 -49 -19z"/>
            
            
    </Aux>
);
}

export default calves;