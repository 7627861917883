import React from 'react';
import { connect } from 'react-redux';

import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = (props) => (
    <header className={props.theme==='dark' ? [classes.Toolbar, classes.ToolbarDark].join(' ') : classes.Toolbar}>
        <DrawerToggle clicked={props.drawerToggleClicked} />
        <div className={classes.Logo}>
            <Logo />
        </div>
        <nav className={classes.DesktopOnly}>
            <NavigationItems isAuthenticated={props.isAuth}/>
        </nav>
    </header>
);

const mapStateToProps = state => {
    return {
      theme: state.auth.settings.theme
    };
  };
  
  
export default connect(mapStateToProps)(toolbar);
