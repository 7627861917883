export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const FETCH_EXERCISE_LOG_START = 'FETCH_EXERCISE_LOG_START';
export const FETCH_EXERCISE_LOG_SUCCESS = 'FETCH_EXERCISE_LOG_SUCCESS';
export const FETCH_EXERCISE_LOG_FAIL = 'FETCH_EXERCISE_LOG_FAIL';

export const PUT_EXERCISE_LIST_IN_STATE = 'PUT_EXERCISE_LIST_IN_STATE';

export const CALCULATE_MUSCLE_RAGS = 'CALCULATE_MUSCLE_RAGS';
export const CALCULATE_MUSCLE_GRADIENTS = 'CALCULATE_MUSCLE_GRADIENTS';

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGNIN = 'SIGNIN'
export const SIGNOUT = 'SIGNOUT'

export const UPDATE_SETTINGS_START = 'UPDATE_SETTINGS_START'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL'

export const GET_SETTINGS_START = 'GET_SETTINGS_START'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL'
