import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';
const hamstrings = (props) => {
    let click = () => {props.clicked('hamstrings')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('hamstrings') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['hamstrings'];
    }

    return (
    <Aux>

        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M1897 3518 c-34 -26 -47 -91 -79 -393 -17 -159 -22 -273 -23 -470 0
            -323 13 -405 98 -605 44 -102 82 -170 96 -170 46 0 72 839 36 1203 -17 183
            -47 376 -64 420 -11 29 -37 35 -64 15z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M2400 3496 c-32 -86 -68 -387 -80 -681 -8 -205 0 -607 15 -755 13
            -121 27 -191 38 -187 33 10 134 247 169 397 20 86 22 122 22 365 1 307 -31
            676 -70 812 -20 72 -74 100 -94 49z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}         
            d="M1717 3424 c-84 -89 -143 -249 -172 -469 -29 -212 -12 -784 26 -903
            14 -43 23 -35 82 76 l47 89 0 109 c0 225 31 702 61 944 10 85 19 163 19 173 0
            28 -26 20 -63 -19z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}         
            d="M2580 3432 c0 -10 7 -65 14 -123 29 -206 66 -775 66 -1008 0 -93 1
            -96 46 -180 60 -111 65 -117 77 -95 23 41 39 223 44 504 4 242 2 317 -11 415
            -23 161 -51 265 -97 355 -57 110 -139 188 -139 132z"/>
    </Aux>
);
}

export default hamstrings;