import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import classes from './NavigationItem.module.css';

const navigationItem = (props) => (
    <li className={props.theme==='dark' ? [classes.NavigationItem, classes.NavigationItemDark].join(' ') : classes.NavigationItem}>
        <NavLink activeClassName={classes.active} to={props.link} exact>
            {props.children}
        </NavLink>
    </li>
);

const mapStateToProps = state => {
    return {
      theme: state.auth.settings.theme
    };
  };
  
  
export default connect(mapStateToProps)(navigationItem);