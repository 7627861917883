import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const traps = (props) => {

    let click = () => {props.clicked('traps')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('traps') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['traps'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2067 7040 c-30 -24 -53 -84 -62 -160 -3 -30 -15 -84 -27 -119 -25
        -76 -82 -138 -176 -191 -35 -19 -64 -39 -65 -45 -1 -5 45 -19 103 -31 58 -12
        147 -33 199 -48 51 -15 96 -24 100 -21 3 4 6 136 6 294 -1 256 -3 291 -18 315
        -21 31 -27 32 -60 6z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2240 7035 c-8 -9 -17 -65 -22 -128 -10 -121 -7 -481 4 -492 3 -3 39
        3 80 15 40 12 128 34 196 49 68 14 124 31 124 36 1 6 -32 29 -72 52 -133 77
        -169 132 -195 305 -15 95 -33 138 -67 162 -28 20 -32 20 -48 1z"/>

    </Aux>
);
}

export default traps;