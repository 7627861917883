import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const biceps = (props) => {
    let click = () => {props.clicked('biceps')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('biceps') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['biceps'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M609 4155 c-31 -47 -70 -84 -157 -149 -34 -26 -69 -60 -77 -75 -48 -93 -76 -299 -55 -411 14 -80 57 -217 70 -225 14 -9 142 191 180 281 33 77 34 84 41 269 4 105 12 211 19 237 6 26 10 62 8 80 l-3 33 -26 -40z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1771 4150 c-1 -25 4 -61 10 -80 7 -19 15 -120 18 -225 7 -185 8 -192 40 -267 37 -88 167 -291 181 -283 13 8 56 146 70 225 21 112 -7 318 -55 411 -8 15 -50 55 -93 88 -75 57 -118 99 -154 151 l-17 25 0 -45z"/>
    </Aux>
    );
};

export default biceps;