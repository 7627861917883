import React from 'react';
import { connect } from 'react-redux'

import classes from './Key.module.css';

const key = (props) => (
    <div className={props.theme==='light' ? classes.Key : [classes.Key,classes.KeyDark].join(' ')} onClick={props.clicked}>
        <h6><strong>&nbsp;LAST WORKOUT</strong></h6>
        <p>
            <span>
                <span className={classes.Box}>
                    <svg viewBox="0 0 200 200" width="15px" height="15px" >
                        <rect fill="url(#patternRed)" width="200px" height="200px" x="0" y="0" />
                    </svg>
                </span>
                <span className={classes.KeyItem}>&nbsp;&gt;&nbsp;{props.rt}{props.rt==='1' || props.rt===1 ? ' Day' : ' Days'}</span>
            </span>
        </p>

        <p>
            <span>
                <span className={classes.Box}>
                    <svg viewBox="0 0 200 200" width="15px" height="15px" >
                        <rect fill="url(#patternDarkAmber)" width="200px" height="200px" x="0" y="0" />
                    </svg>
                </span>
                <span className={classes.KeyItem}></span>
            </span>
        </p>

        <p>
            <span>
                <span className={classes.Box}>
                    <svg viewBox="0 0 200 200" width="15px" height="15px" >
                        <rect fill="url(#patternAmber)" width="200px" height="200px" x="0" y="0" />
                    </svg>
                </span>
                <span className={classes.KeyItem}></span>
            </span>
        </p>            
               
        <p>
            <span>
                <span className={classes.Box}>
                    <svg viewBox="0 0 200 200" width="15px" height="15px" >
                        <rect fill="url(#patternGreen)" width="200px" height="200px" x="0" y="0" />
                    </svg>
                </span>
                <span className={classes.KeyItem}></span>
            </span>
        </p>                   
               
        <p>
            <span>
                <span className={classes.Box}>
                    <svg viewBox="0 0 200 200" width="15px" height="15px" >
                        <rect fill="url(#patternDarkGreen)" width="200px" height="200px" x="0" y="0" />
                    </svg>
                </span>
                <span className={classes.KeyItem}>&nbsp;&lt;&nbsp;{props.gt}{props.gt==='1' || props.gt===1 ? ' Day' : ' Days'}</span>
            </span>
        </p>                   
    </div>
);

const mapStateToProps = state => {
    return {
        theme: state.auth.settings.theme
    };
};

export default connect(mapStateToProps)(key);
