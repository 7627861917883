import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from './hoc/asyncComponent/asyncComponent';

import AuthUI from './containers/AuthUI/AuthUI';
import Layout from './hoc/Layout/Layout';
import FullBody from './containers/FullBody/FullBody';
import Signout from './containers/AuthUI/Signout/Signout';

const asyncContact = asyncComponent(() => {
  return import('./containers/Contact/Contact');
});

const asyncSettings = asyncComponent(() => {
  return import('./containers/Settings/Settings');
});

const asyncStats = asyncComponent(() => {
  return import('./containers/Stats/Stats');
});

class App extends Component {

  state = {
    showInstallPrompt: false,
    showInstallInstruct: false
  }

  installPrompt = null;

  componentDidMount () {
    console.log("Listening for Install prompt");
    window.addEventListener('beforeinstallprompt',e => {
      // For older browsers
      e.preventDefault();
      console.log("Install Prompt fired");
      this.installPrompt = e;
      // See if the app is already installed, in that case, do nothing
      if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
        return false;
      }
      // Set the state variable to make button visible
      this.setState({showInstallPrompt: true});
    });

    // Checks if should display install instructions in sidebar
    if (this.isIos() && !this.isInStandaloneMode()) {
      this.setState({showInstallInstruct: true});
    }

    }


  // Detects if device is on iOS 
  isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  // Detects if device is in standalone mode
  isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

 

  installApp = async () => {
    if(!this.installPrompt) return false;
    this.installPrompt.prompt();
    let outcome = await this.installPrompt.userChoice;
    if(outcome.outcome === 'accepted'){
      console.log("App Installed")
    }
    else{
      console.log("App not installed");
    }
    // Remove the event reference
    this.installPrompt=null;
    // Hide the button
    this.setState({showInstallPrompt: false});
  }

  dismissInstallAppHandler = () => {
    this.setState({showInstallPrompt: false});
  }


  render () {

    this.props.theme === 'dark' ? document.body.style.backgroundColor = "#464646" : document.body.style.backgroundColor = "white";
    
    let routes = (
      <Switch>
        <Route path="/auth" component={AuthUI}/>
        <Redirect to="/auth" />
    </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/signout" component={Signout}/>
          <Route path="/settings" component={asyncSettings}/>
          <Route path="/stats" component={asyncStats} />
          <Route path="/contact" component={asyncContact} />
          <Route path="/" exact component={FullBody}/>
          <Redirect to="/" />
      </Switch>
      );
    }

    return (
      <div>
        <Layout 
          showInstallPrompt={this.state.showInstallPrompt} 
          showInstallInstruct={this.state.showInstallInstruct}
          accepted={this.installApp} 
          dismissed={this.dismissInstallAppHandler}
        >
          {routes}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isSignedIn,
    theme: state.auth.settings.theme
  };
};


export default connect(mapStateToProps)(App);
