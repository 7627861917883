import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';


const forearms = (props) => {
    let click = null;
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('forearms') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['forearms'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M262 3347 c-18 -67 -44 -162 -57 -212 -36 -128 -73 -317 -80 -410 l-6 -80 41 70 c23 39 71 116 106 172 86 137 106 190 108 283 1 60 -6 96 -33 180 -18 58 -36 108 -40 112 -3 4 -21 -48 -39 -115z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M2108 3465 c-3 -6 -21 -57 -39 -115 -57 -176 -45 -270 53 -427 23 -38 70 -115 106 -173 l64 -105 -6 75 c-10 114 -43 277 -93 460 -25 91 -53 194 -62 230 -10 35 -20 60 -23 55z"/>
        {/* <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M489 3153 c-83 -89 -357 -506 -385 -586 -8 -22 -14 -43 -14 -48 0 -5 22 -9 49 "/> */}
        {/* <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1875 3170 c11 -54 88 -255 170 -444 40 -94 79 -191 86 -216 7 -25 14 -47 15 -48 1 -2 20 8 41 22 27 19 51 26 85 26 53 0 54 1 34 57 -30 86 -323 527 -397 598 l-41 40 7 -35z"/> */}
    </Aux>
    );
};

export default forearms;