import React, { Component } from 'react';
import { connect } from 'react-redux'

import { InputGroup, Button, Input } from 'reactstrap';

// import ExFormElement from '../../components/UI/Inputs/ExFormElement/ExFormElement';
// import Button from '../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './CustomExForm.module.css';
import { checkValidity } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';
import { muscleList } from '../../../shared/staticLists';
import axios from '../../../axios';

class CustomExForm extends Component {
    state = {
        customForm: {
            name: {
                value: '',
                touched: false,
                validation: {
                    required: true,
                    isAlpha: true
                },                
                elementType: 'text',
                placeholder: "",
                valid: false,
                label: 'Muscles will turn amber after this number of days',
                units: 'Days',
                displayName: 'Green Time'
            }
            
        },
        muscles: [],
        formIsValid: false,
        settingsUpdated: false,
        loading: false,
        response: null,
    }
    

    inputChangedHandler = (event, id) => {
        const updatedRagForm = {
            ...this.state.customForm
        };

        const updatedFormElement = {
            ...updatedRagForm[id]
        };

        updatedFormElement.value = event.target.value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedRagForm[id] = updatedFormElement;

        let formIsValid = true;
        for (let inputId in updatedRagForm) {
            formIsValid = updatedRagForm[inputId].valid && formIsValid;
        }
        
        this.setState({customForm: updatedRagForm, formIsValid: formIsValid});

    }

    addMuscleHandler = (muscle) => {
        const updatedMuscles = [...this.state.muscles]
        updatedMuscles.push(muscle);
        this.setState({muscles: updatedMuscles});
    }

    removeMuscleHandler = (muscle) => {
        const updatedMuscles = [...this.state.muscles]
        updatedMuscles.splice(updatedMuscles.indexOf(muscle),1);
        this.setState({muscles: updatedMuscles});
    }
    
    addCustomExerciseHandler = () => {
        const valid = this.state.customForm.name.valid && this.state.muscles.length > 0;
        if (valid) {
            this.setState({loading: true});
        //post custom ex to db, then dispatch action to get exercise log etc and make sure getting custom exercises is part of this
        const customExercise = {};
        customExercise[this.state.customForm.name.value] = this.state.muscles;
        axios.post('/users/' + this.props.userId + '/customExercises.json?auth=' + this.props.token, customExercise)
        .then(response => {
            this.setState({loading: false, response: response.data});
            this.props.added();
        })
        .catch(error => {
            this.setState({loading: false});
            this.props.failed();
        });
        }
    }

    render () {
        // console.log(this.state.muscles);
        const muscleArray = [...this.state.muscles]
        let muscles = muscleList.map(muscleName => {
            return (
            <div 
                key={muscleName} 
                className={muscleArray.indexOf(muscleName) >= 0 ? [classes.Muscle, classes.MuscleSelected].join(' ') : classes.Muscle}
                onClick={muscleArray.indexOf(muscleName) >= 0 ? () => this.removeMuscleHandler(muscleName)  : () => this.addMuscleHandler(muscleName)}
            >{muscleName}
            </div>);
        });

        let form = (
            <form onSubmit={this.addCustomExerciseHandler}>
                <p className={classes.Label}>Exercise Name</p>
                <p className={classes.Sublabel}>This is the name you'll see when logging a new exercise</p>
                <InputGroup >
                    <Input 
                        onChange={(event) => this.inputChangedHandler(event, 'name')}
                        invalid={!this.state.customForm.name.valid && this.state.customForm.name.touched}
                        value={this.state.customForm.name.value}
                        type="text"
                    />
                </InputGroup>

                <p className={classes.Label}>Muscles Trained</p>
                <p className={classes.Sublabel}>Which muscles are targeted by this exercise?</p>
                <div className={classes.Muscles}>
                    {muscles}
                </div>
                <br />

                <Button onClick={this.addCustomExerciseHandler} color="success">CREATE EXERCISE</Button>
            </form>

        );


        if (this.state.loading) {
            form = <Spinner /> 
        }

        return (

            <div>
                <h3>Add Custom Exercise</h3>
                {form}
            </div>


        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateSettings: (token, userId, settings) => dispatch(actions.updateSettings(token, userId, settings))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CustomExForm);