import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const shoulders = (props) => {

    let click = () => {props.clicked('shoulders')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('shoulders') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['shoulders'];
    } 
    

    return (
    <Aux>

        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1200 6361 c-106 -33 -199 -147 -229 -284 -19 -83 -31 -211 -21 -221
        25 -25 216 150 371 341 54 67 99 128 99 136 0 8 -14 20 -31 26 -36 12 -151 13
        -189 2z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2970 6352 c-17 -5 -25 -15 -25 -30 0 -48 309 -388 417 -460 61 -40
        64 -29 37 148 -22 148 -97 273 -193 322 -44 23 -186 35 -236 20z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1473 6232 c-22 -14 -223 -267 -220 -276 2 -6 44 -22 93 -36 49 -15
        147 -50 218 -79 70 -29 131 -50 134 -46 3 3 -8 34 -26 68 -17 34 -48 103 -68
        152 -70 171 -106 232 -131 217z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2857 6212 c-20 -23 -48 -81 -92 -188 -20 -49 -53 -122 -73 -161 -19
        -40 -33 -76 -29 -79 4 -4 64 17 133 46 70 29 167 65 217 79 50 15 92 31 94 36
        3 11 -200 265 -222 277 -7 4 -19 0 -28 -10z"/>
    </Aux>
);
}

export default shoulders;