import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './Modal.module.css';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.show !== this.props.show || nextProps.children !== this.props.children);
    }

    render () {
        return (
            <Auxiliary>
            <Backdrop show={this.props.show} clicked={this.props.modalClosed}/>
            <div className={this.props.theme==='dark' ? [classes.Modal, classes.ModalDark].join(' ') : classes.Modal}
                style={{
                transform: this.props.show ? 'translateY(0)' : 'translateY(-50vh)',
                opacity: this.props.show ? '1' : '0'
            }}>
                {this.props.children}
            </div>
            </Auxiliary>
        );
    }

}

const mapStateToProps = state => {
    return {
      theme: state.auth.settings.theme
    };
  };
  
  
export default connect(mapStateToProps)(Modal);