import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const shoulders = (props) => {
    
    let click = () => {props.clicked('shoulders')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('shoulders') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['shoulders'];
    }

    return (
        <Aux>
            <path 
            onClick={click}
            className={classname}
            fill={fill}
            d="M862 5489 c-84 -11 -140 -39 -206 -104 -65 -64 -124 -176 -133 -252
            -7 -66 -4 -66 105 -13 104 50 152 88 207 165 61 85 133 154 184 177 32 15 40
            22 30 29 -17 10 -100 10 -187 -2z"/>
            <path 
            onClick={click}
            className={classname}
            fill={fill}
            d="M2100 5490 c0 -5 16 -16 35 -24 46 -19 127 -96 177 -168 43 -61 131
            -140 186 -166 27 -13 38 -14 65 -4 19 7 41 12 50 12 24 0 21 21 -14 107 -59
            141 -158 216 -321 243 -80 13 -178 13 -178 0z"/>
        </Aux>
    );
    
};

export default shoulders;