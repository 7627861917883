import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const obliques = (props) => {
    let click = () => {props.clicked('obliques')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('obliques') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['obliques'];
    }
    
    return (
<Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M693 3704 c3 -16 17 -82 31 -148 14 -65 27 -121 30 -123 2 -3 8 17 12 44 8 59 38 140 63 173 l20 25 -62 21 c-34 11 -71 24 -81 29 -17 7 -18 5 -13 -21z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1680 3715 c-14 -8 -47 -19 -73 -25 l-48 -12 21 -32 c33 -48 51 -94 62 -158 6 -32 12 -57 15 -55 2 3 15 58 28 123 14 66 29 131 34 147 11 31 1 35 -39 12z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M874 3662 c-6 -4 -23 -29 -36 -57 -46 -92 -69 -176 -74 -270 -7 -124 -20 -176 -71 -279 -23 -49 -43 -95 -43 -103 0 -20 69 -92 114 -118 64 -36 142 -56 171 -43 13 6 27 18 30 27 3 8 1 74 -6 146 -8 98 -9 168 0 283 13 185 14 303 2 335 -7 18 -66 88 -74 87 -1 0 -7 -4 -13 -8z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}
        d="M1489 3637 c-17 -17 -35 -42 -40 -55 -12 -31 -11 -150 2 -334 9 -115 8 -185 0 -283 -7 -72 -9 -138 -6 -146 25 -66 189 -18 278 80 21 23 37 47 37 54 0 7 -18 51 -41 98 -55 113 -69 161 -69 244 0 39 -7 100 -14 136 -17 76 -82 225 -102 233 -8 3 -28 -9 -45 -27z"/>
    </Aux>
    );
    
};

export default obliques;