import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const shoulders = (props) => {

    let click = () => {props.clicked('shoulders')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('shoulders') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['shoulders'];
    }

    return (
    <Aux>

        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M733 5790 c-33 -7 -88 -47 -135 -97 -36 -38 -96 -153 -103 -200 -4 -21 -20 -96 -36 -168 -16 -71 -32 -140 -35 -152 -2 -13 -1 -23 4 -23 4 0 43 21 87 46 153 88 220 123 289 149 131 49 192 97 216 171 10 29 9 43 -4 78 -21 54 -55 91 -141 148 -79 53 -97 59 -142 48z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2205 5775 c-50 -22 -155 -101 -181 -137 -14 -18 -30 -49 -36 -69 -9 -32 -8 -44 12 -84 29 -60 88 -103 205 -149 50 -20 127 -57 170 -82 154 -89 206 -116 211 -112 4 5 -52 265 -78 363 -26 95 -108 207 -189 257 -36 23 -80 28 -114 13z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1015 5435 c-22 -22 -68 -55 -102 -73 -35 -18 -63 -37 -63 -41 0 -4 44 -39 98 -76 53 -38 110 -79 126 -92 16 -13 31 -22 33 -19 3 2 -1 31 -7 63 -7 32 -20 108 -29 168 l-16 110 -40 -40z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1941 5371 c-7 -53 -20 -129 -28 -169 -8 -41 -13 -76 -10 -78 2 -2 33 17 68 44 35 27 95 70 133 97 l69 48 -67 34 c-37 19 -87 53 -110 77 l-43 42 -12 -95z"/>
    </Aux>
);
}

export default shoulders;