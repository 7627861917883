import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const glutes = (props) => {

    let click = () => {props.clicked('glutes')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('glutes') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['glutes'];
    }


    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}  
        d="M890 3855 c-91 -34 -129 -78 -162 -192 -33 -111 -38 -133 -29 -133 4 0 18 18 30 41 13 22 49 67 80 99 94 96 198 136 383 147 59 3 105 9 103 14 -3 4 -45 17 -94 28 -121 29 -226 27 -311 -4z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1805 3849 c-44 -11 -83 -23 -88 -28 -4 -4 41 -11 100 -14 186 -11 290 -51 384 -147 31 -32 67 -77 80 -99 25 -45 40 -54 32 -18 -32 141 -73 232 -123 269 -80 58 -236 74 -385 37z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1115 3786 c-193 -45 -310 -135 -365 -281 -32 -85 -32 -255 -1 -336 60 -158 207 -251 396 -253 86 -1 94 1 175 40 158 77 163 88 168 373 3 151 0 227 -9 259 -19 67 -91 160 -144 183 -58 26 -147 32 -220 15z"/>        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1727 3780 c-63 -16 -95 -38 -136 -93 -64 -85 -81 -151 -81 -321 0 -153 17 -277 46 -325 19 -32 85 -77 164 -111 75 -33 208 -34 293 -2 127 46 205 119 248 230 32 83 31 252 -1 337 -31 82 -84 149 -153 196 -104 69 -287 112 -380 89z"/>            
    </Aux>
);
}

export default glutes;