import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const triceps = (props) => {

    let click = () => {props.clicked('triceps')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('triceps') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['triceps'];
    }

    return (
    <Aux>

        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M625 5207 c-86 -47 -167 -101 -192 -129 -30 -31 -40 -90 -58 -328 -19 -240 -31 -317 -61 -399 -31 -82 -31 -93 2 -89 25 3 30 12 60 107 18 57 40 111 48 120 15 14 17 14 32 -1 16 -15 16 -24 0 -120 -9 -57 -19 -114 -22 -128 -2 -14 0 -31 5 -39 27 -41 188 74 236 168 27 54 90 252 110 348 22 102 18 146 -21 267 -20 61 -41 142 -48 180 -6 38 -16 71 -21 72 -6 2 -37 -11 -70 -29z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}  
        d="M2307 5223 c-3 -5 -8 -33 -12 -63 -4 -30 -25 -110 -46 -176 -42 -133 -46 -174 -24 -277 20 -96 83 -294 110 -348 49 -96 209 -210 236 -168 5 8 7 25 4 39 -2 14 -12 71 -21 128 -16 95 -16 105 -1 119 26 26 42 3 79 -110 34 -106 41 -117 72 -117 21 0 21 0 -19 130 -20 65 -29 130 -41 275 -22 286 -37 381 -65 412 -28 29 -137 102 -209 138 -54 28 -57 28 -63 18z"/>
    </Aux>
);
}

export default triceps;