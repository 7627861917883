import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const rhomboid = (props) => {

    let click = () => {props.clicked('rhomboid')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('rhomboid') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['rhomboid'];
    }


    return (
    <Aux>

    <path 
    onClick={click}
    className={classname}
    fill={fill} 
    d="M930 5856 c-102 -29 -120 -45 -77 -67 77 -41 151 -117 197 -202 24 -43 45 -143 59 -277 6 -53 20 -122 31 -154 42 -122 171 -326 281 -443 l44 -47 8 89 c4 50 6 250 5 445 -3 350 -3 356 -28 425 l-25 70 -171 5 c-151 4 -175 7 -193 24 -22 20 -41 74 -41 119 0 31 -16 34 -90 13z"/>
    <path 
    onClick={click}
    className={classname}
    fill={fill} 
    d="M1990 5833 c0 -45 -19 -99 -41 -119 -18 -17 -42 -20 -193 -24 l-171 -5 -25 -70 c-25 -69 -25 -75 -28 -420 -1 -192 1 -393 5 -445 l8 -94 44 47 c113 120 247 333 284 452 9 28 23 108 32 180 23 185 33 216 107 322 20 29 102 97 154 129 26 16 12 28 -63 53 -88 30 -113 28 -113 -6z"/>
    </Aux>
);
}

export default rhomboid;