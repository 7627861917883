import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';


const shins = (props) => {
    let click = null;
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('shins') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['shins'];
    }


    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1302 1433 c-28 -60 -56 -128 -62 -152 -14 -52 -13 -219 1 -311 5
        -36 12 -252 16 -480 6 -463 2 -448 70 -262 53 149 101 348 114 478 18 183 6
        482 -24 630 -29 138 -47 204 -57 204 -4 0 -30 -48 -58 -107z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1784 1508 c-18 -35 -45 -118 -65 -198 -10 -40 -14 -133 -14 -340 0
        -313 3 -334 71 -575 46 -160 92 -280 116 -301 18 -18 19 -16 11 67 -4 46 -8
        203 -8 349 0 212 5 304 23 462 28 236 25 262 -48 429 -27 63 -50 120 -50 127
        0 23 -19 12 -36 -20z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1045 1175 c-10 -379 10 -1051 34 -1093 7 -12 14 -7 36 28 15 24 39
        75 54 114 25 69 26 77 26 301 -1 127 -7 280 -14 340 -13 108 -73 397 -110 525
        l-18 65 -8 -280z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2082 1383 c-31 -105 -90 -420 -112 -597 -16 -131 -14 -410 4 -495
        16 -76 73 -203 93 -209 34 -10 37 46 41 687 2 378 0 637 -5 642 -6 6 -14 -5
        -21 -28z"/>
    </Aux>
);
}

export default shins;