import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const forearms = (props) => {
    let click = null;
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('forearms') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['forearms'];
    }

    return (
    <Aux>

        <path 
        onClick={click}
        className={classname}
        fill={fill}  
            d="M687 4904 c-17 -28 -61 -235 -72 -344 -27 -253 -30 -612 -5 -583 11
            12 74 174 109 278 70 211 79 380 27 540 -26 84 -49 125 -59 109z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M3630 4833 c-86 -229 -56 -472 106 -838 l17 -40 9 40 c11 45 3 351
            -13 525 -13 141 -65 370 -84 370 -8 0 -23 -26 -35 -57z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}         
            d="M979 4710 c-59 -93 -126 -239 -157 -345 -11 -39 -44 -146 -72 -238
            -69 -221 -61 -231 40 -51 28 50 69 136 91 190 47 114 121 394 117 439 l-3 30
            -16 -25z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill}         
            d="M3360 4691 c0 -49 75 -328 115 -426 64 -159 172 -348 182 -318 5 14
            -136 467 -172 553 -32 76 -102 207 -116 215 -5 4 -9 -7 -9 -24z"/>
    </Aux>
);
}

export default forearms;