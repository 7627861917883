import React from 'react';

import classes from './Alert.module.css';


const alert = (props) => {
    let attachedClasses = [classes.Alert, classes.Hide];
    if (props.show) {
        attachedClasses = [classes.Alert, classes.Show];
    }
    
    return (
        <div className={attachedClasses.join(' ')}>
            <p>{props.children}</p>
        </div>

    );
};

export default alert;