import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const lowerBack = (props) => {


    let click = () => {props.clicked('lowerBack')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('lowerBack') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['lowerBack'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}  
            d="M2098 4723 c-9 -10 -39 -53 -67 -97 -28 -43 -67 -96 -86 -117 -19
            -22 -35 -47 -35 -58 0 -10 39 -55 88 -102 49 -46 98 -97 110 -114 17 -25 23
            -28 34 -17 10 10 13 64 13 230 0 278 -10 328 -57 275z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M2233 4703 c-4 -21 -8 -136 -8 -255 0 -169 3 -220 13 -230 12 -11 17
            -9 30 12 9 14 55 64 104 112 52 51 88 95 88 107 0 11 -17 39 -37 63 -21 24
            -56 74 -78 112 -42 72 -78 116 -94 116 -6 0 -14 -17 -18 -37z"/>
            
    </Aux>
);
}

export default lowerBack;