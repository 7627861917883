import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const obliques = (props) => {

    let click = () => {props.clicked('obliques')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('obliques') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['obliques'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M964 4183 c-4 -16 -36 -86 -71 -157 -35 -70 -63 -129 -61 -131 2 -2 41 10 88 26 101 34 105 34 175 10 l55 -19 0 20 c0 25 -79 175 -124 235 -36 49 -53 54 -62 16z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1983 4158 c-46 -65 -123 -212 -123 -236 l0 -20 55 19 c70 24 74 24 175 -10 47 -16 87 -28 89 -26 2 2 -26 61 -62 132 -35 70 -67 140 -71 156 -9 38 -28 34 -63 -15z"/>
            
    </Aux>
);
}

export default obliques;