import React from 'react';
import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const pecs = (props) => {

    let click = () => {props.clicked('pecs')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('pecs') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill =  props.colours['pecs'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
            d="M1073 5406 c-86 -28 -132 -81 -173 -201 -20 -59 -25 -135 -13 -196 8
            -40 90 -127 149 -157 96 -48 247 -82 372 -82 66 0 75 3 97 26 34 37 37 62 33
            300 -3 204 -4 210 -28 246 -44 66 -66 72 -240 75 -109 2 -167 -1 -197 -11z"
        />
        <path 
        onClick={click}
        className={classname}
        fill={fill}
            d="M1736 5409 c-51 -12 -74 -33 -99 -89 -18 -40 -21 -69 -23 -264 l-3
            -219 37 -35 37 -34 110 5 c166 9 300 50 396 124 74 56 99 136 79 257 -17 110
            -73 196 -154 239 -36 19 -59 22 -191 24 -82 1 -168 -3 -189 -8z"
        />
    </Aux>
    );
}

export default pecs;