import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const lowerBack = (props) => {


    let click = () => {props.clicked('lowerBack')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('lowerBack') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['lowerBack'];
    }
    
    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill}  
        d="M1421 4228 c-59 -155 -66 -169 -128 -253 -35 -46 -63 -86 -63 -89 0 -3 15 -6 33 -6 24 0 54 -14 104 -49 39 -27 73 -57 76 -65 17 -45 22 9 24 264 0 154 -3 280 -7 280 -5 0 -22 -37 -39 -82z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M1547 4294 c-7 -13 -3 -554 4 -554 4 0 9 6 12 14 3 8 38 37 78 65 52 37 82 51 106 51 18 0 33 3 33 6 0 3 -29 44 -64 92 -50 66 -74 111 -106 196 -47 125 -55 142 -63 130z"/>
            
    </Aux>
);
}

export default lowerBack;