import React from 'react';

import Aux from '../../../../../hoc/Auxiliary/Auxiliary';
import classes from '../../../../Muscles/Muscles.module.css';

const lats = (props) => {

    let click = () => {props.clicked('lats')};
    let classname = classes.Muscle;
    let fill = null;

    if (props.icon) {
         click = null;
         classname = props.muscles.indexOf('lats') >= 0 ? classes.IconSelected : classes.Icon;
    } else {
        fill = props.colours['lats'];
    }

    return (
    <Aux>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M753 5264 c4 -20 16 -70 28 -111 28 -92 58 -126 131 -144 98 -24 256 -9 245 24 -8 25 -324 236 -391 260 -17 7 -18 4 -13 -29z"/>
      
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2205 5263 c-64 -33 -326 -205 -344 -227 -9 -10 -11 -20 -5 -26 17 -17 136 -28 196 -19 127 20 146 40 189 197 28 100 25 106 -36 75z"/>
        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M835 4929 c4 -35 15 -81 25 -104 21 -48 70 -247 101 -403 20 -102 27 -120 75 -193 29 -44 77 -125 106 -181 29 -55 59 -103 67 -106 47 -18 205 346 238 548 l16 98 -69 86 c-38 48 -99 131 -135 184 l-67 97 -148 0 c-126 0 -154 3 -183 18 l-33 19 7 -63z"/>        <path 
        onClick={click}
        className={classname}
        fill={fill} 
        d="M2125 4958 c-44 -18 -64 -20 -177 -14 l-126 6 -68 -97 c-37 -54 -98 -138 -137 -186 l-69 -88 12 -82 c6 -45 25 -125 42 -177 56 -177 161 -390 192 -390 13 0 50 59 114 183 23 42 57 97 77 121 33 41 39 58 75 233 22 103 55 238 75 298 30 96 55 217 44 214 -2 -1 -26 -10 -54 -21z"/>    </Aux>
);
}

export default lats;