export const exerciseList = {
    'Arnold Press': ['Shoulders'],
    'Back Extension': ['lowerBack','glutes'],
    'Barbell Lunge': ['hamstrings', 'quads', 'glutes'],
    'Barbell Shrug': ['traps'],
    'Barbell Squat': ['hamstrings', 'quads', 'glutes'],
    'Barbell Upright Row': ['Shoulders'],
    'Bench Press': ['pecs'],
    'Bent Over Barbell Row': ['rhomboid','lats'],
    'Bent Over Dumbbell Row': ['rhomboid','lats'],
    'Cable Press-Downs': ['triceps'],
    'Calf Press': ['calves'],
    'Calf Raise': ['calves'],
    'Chest Dumbbell Fly': ['pecs'],
    'Chin-Up': ['biceps','rhomboid', 'lats'],
    'Close Grip Bench Press': ['triceps'],
    'Close Grip Push-Up': ['shoulders', 'triceps', 'pecs'],
    'Deadbug Arm-Leg Extension': ['abs'],
    'Deadlift':  ['hamstrings', 'quads', 'glutes', 'lowerBack'],
    'Dips': ['triceps', 'chest'],
    'Dumbbell Bulgarian Split Squat': ['hamstrings', 'quads', 'glutes'],
    'Dumbbell Lunge': ['hamstrings', 'quads', 'glutes'],
    'Dumbbell Press': ['pecs'],
    'Dumbbell Shoulder Fly': ['Shoulders'],
    'Dumbbell Shrug': ['traps'],
    'Dumbbell Squat': ['hamstrings', 'quads', 'glutes'],
    'Dumbbell Upright Row': ['Shoulders'], 
    'Front Dumbbell Raise': ['Shoulders'],
    'Glute Bridge': ['glutes', 'hamstrings'],
    'Hammer Curl': ['biceps'],
    'Hip Abduction': ['abductors'],
    'Hip Adduction': ['adductors'],
    'Knee Raise': ['abs'],
    'Lat Pull-Down': ['lats'],
    'Lateral Dumbbell Raise': ['Shoulders'],
    'Laying Barbell Tricep Extension': ['triceps'], 
    'Laying Dumbbell Tricep Extension': ['triceps'], 
    'Leg Curl': ['hamstrings'],
    'Leg Extension': ['quads'],
    'Leg Press': ['glutes', 'quads', 'hamstrings'],
    'Lunge': ['hamstrings', 'quads', 'glutes'],
    'Plank': ['abs'],
    'Pull-Up': ['lats','rhomboid'],
    'Seated Barbell Curl': ['biceps'],
    'Seated Dumbbell Curl': ['biceps'],
    'Seated Overhead Barbell Press': ['shoulders'],
    'Seated Overhead Dumbbell Press': ['shoulders'],
    'Seated Row': ['rhomboid','lats'],
    'Side Plank': ['obliques'],
    'Single-Arm Row': ['rhomboid','lats'],
    'Standing Barbell Curl': ['biceps'],
    'Standing Barbell Tricep Extension': ['triceps'], 
    'Standing Dumbbell Curl': ['biceps'],
    'Standing Dumbbell Tricep Extension': ['triceps'], 
    'Standing Overhead Barbell Press': ['shoulders'],
    'Standing Overhead Dumbbell Press': ['shoulders'],
    'Superman': ['lowerBack', 'glutes'],
    'Tricep Extension': ['triceps'],
    'Wide Grip Push-Up': ['pecs', 'triceps', 'shoulders']
}

export const muscleList = [
    'abductors',
    'abs',
    'adductors',
    'biceps',
    'calves',
    'glutes',
    'hamstrings',
    'lats',
    'lowerBack',
    'obliques',
    'pecs',
    'quads',
    'rhomboid',
    'shoulders' ,
    'traps',
    'triceps'
];

export const nonClickableMuslceList = [
    'neck',
    'forearms',
    'shins'
];